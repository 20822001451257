import React, { ReactNode } from 'react';

import { AdminHeader } from './AdminHeader';
import { AdminFooter } from "./AdminFooter";

interface AdminLayoutProps {
  children: ReactNode;
}

export const AdminLayout = (props: AdminLayoutProps) => {
  const { children } = props;

  return (
    <div className="relative flex flex-col min-h-screen">
      <div className="fixed top-0 left-0 right-0 z-30">
        <AdminHeader />
      </div>
      {/* Padding hack */}
      <div className="h-16"></div>
      {children}
      <div className="h-16"></div>
      <div className="fixed bottom-0 left-0 right-0 z-30">
        <AdminFooter />
      </div>
    </div>
  );
};
