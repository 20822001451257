import { CreateVariationRequest, UpdateVariationRequest, VariationObject } from "@acrelec-cloud/recommendation-shared";
import { cast, flow, getEnv, types } from "mobx-state-tree";
import { VariationModel } from "src/app/models/variation.model";
import { RootSdkType } from "src/app/stores/root.store";
import { createStringEntityReference, createStringEntityStore } from "src/app/utils/string-entity.store";

export interface FindVariationsResponse {
  items: VariationObject[];
  count: number;
}

export const VariationReference = createStringEntityReference("variations", VariationModel);

export const VariationsStore = createStringEntityStore("Variation", VariationModel)
  .props({
    variations: types.array(VariationModel),
  })
  .actions((self) => ({
    getVariations: flow(function* (campaignId: string) {
      const { microservice } = getEnv<RootSdkType>(self);
      const result: FindVariationsResponse = yield microservice.variations.getAll(campaignId);

      const variations = result.items.map((variation) => {
        return VariationModel.create({
          ...(variation as any),
        });
      });

      self.variations = cast(variations);

      return variations;
    }),
    createVariation: flow(function* (campaignId: string, createVariationData: CreateVariationRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.variations.create(campaignId, createVariationData);
      } catch (error) {
        console.error("ERROR CREATING VARIATION:", error);
        throw error;
      }
    }),
    updateVariation: flow(function* (campaignId: string, variationId: string, updateVariationData: UpdateVariationRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.variations.update(campaignId, variationId, updateVariationData);
      } catch (error) {
        console.error("ERROR UPDATING VARIATION:", error);
        throw error;
      }
    }),
    deleteVariation: flow(function* (campaignId: string, variationId: string) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.variations.delete(campaignId, variationId);
      } catch (error) {
        console.error("ERROR DELETING VARIATION:", error);
        throw error;
      }
    }),
  }));

export const variationsStore = VariationsStore.create();
