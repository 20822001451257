import { observer } from "mobx-react";
import { useCallback } from "react";
import { useAsync } from "react-async";
import Select from "react-select";
import { LoadingSpinner } from 'src/app/components/Shared/LoadingSpinner/LoadingSpinner';
import { useStore } from "src/app/contexts/store.context";

interface RestaurantsSelectorProps {
  value: number | number[] | null;
  onChange: (value: number | number[] | null) => void;
  isDisabled?: boolean;
  isMulti?: boolean;
  className?: string;
}

export const RestaurantsSelector = observer((props: RestaurantsSelectorProps) => {
  const { value, onChange, isDisabled = false, isMulti = false, className = "" } = props;
  const { restaurants: restaurantsStore } = useStore();

  const fetchRestaurants = useCallback(async () => {
    return restaurantsStore.findRestaurants({ limit: 3000 });
  }, [restaurantsStore]);

  const restaurants = useAsync({
    promiseFn: fetchRestaurants,
  });

  if (!restaurants.data) {
    return <LoadingSpinner type="TailSpin" color="black" height="50" />
  }

  const options = restaurants.data.data.map((v) => ({
    value: v.id.toString(),
    label: v.name,
  }));

  const selectValue = Array.isArray(value)
    ? value.map((v: any) => options.find((option) => option.value === v.toString())).filter(value => !!value)
    : options.find((option) => option.value === value?.toString()) || null;

  return (
    <Select
      isMulti={isMulti}
      closeMenuOnSelect
      isDisabled={isDisabled}
      classNamePrefix="tw-select"
      defaultOptions
      className={className}
      noOptionsMessage={() => "No Results"}
      placeholder={"Select restaurants"}
      onChange={(values) => {
        if (Array.isArray(values)) {
          onChange(values.map((value) => value.value));
        } else  {
          onChange((values as any)?.value || null);
        }
      }}
      value={selectValue}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
      options={options}
      menuPosition="fixed"
    />
  );
});
