import { UseFormReturn } from "react-hook-form";
interface EngineFormProps {
  form: UseFormReturn;
}

interface FormInput {
  name: string;
  type: string;
  step?: string;
  placeholder: string;
  options?: { value: string; label: string }[];
  label: (data: any) => string;
  showIf?: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
}

const inputs: FormInput[] = [
  {
    name: "name",
    type: "text",
    placeholder: "Engine with weather",
    label: () => "Name",
    requiredIf: () => true,
  },
  {
    name: "url",
    type: "text",
    placeholder: "reco-engine-weather.prod.koji.fr",
    label: () => "URL",
    requiredIf: () => true,
  },
  {
    name: "description",
    type: "textarea",
    placeholder: "This is an engine with the weather service",
    label: () => "Description",
    requiredIf: () => true,
  },
];

export const EngineForm = (props: EngineFormProps) => {
  const { form } = props;
  const data = form.watch();

  return (
    <>
      {inputs.map((input) =>
        !input.showIf || input.showIf(data) ? (
          <div key={input.name} className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {input.label(data)}
              </label>
            </div>
            <div className="col-span-2">
              {input.type !== "select" && input.type !== "textarea" && input.type !== "tags" && (
                <input
                  {...form.register(input.name)}
                  type={input.type}
                  step={input.step}
                  placeholder={input.placeholder}
                  required={input.requiredIf && input.requiredIf(data)}
                  className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                />
              )}
              {input.type === "textarea" && (
                <textarea
                  {...form.register(input.name)}
                  rows={5}
                  cols={30}
                  placeholder={input.placeholder}
                  required={input.requiredIf && input.requiredIf(data)}
                  className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                />
              )}
              {input.type === "select" && (
                <select
                  {...form.register(input.name)}
                  required={input.requiredIf && input.requiredIf(data)}
                  className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                >
                  {input.options?.map((option, index) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        ) : null
      )}
    </>
  );
};
