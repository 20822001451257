import { VariationParamtersFrom } from "./VariationParameterForm";
import { UseFormReturn } from "react-hook-form";

const booleanType = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

interface FormInput {
  checkedLabel?: string;
  checkedValue?: string | boolean;
  defaultValue?: any;
  label: (data: any) => string;
  name: string;
  options?: { value: any; label: string }[];
  placeholder?: string;
  requiredIf?: (data: any) => boolean;
  showIf?: (data: any) => boolean;
  size?: number;
  step?: string;
  type: string;
  uncheckedLabel?: string;
  uncheckedValue?: string | boolean;
  validationPattern?: string;
}

export const parametersType: any[] = [];

export interface VariationFormProps {
  form: UseFormReturn;
  parameters: any;
}

export const VariationForm = ({ form, parameters }: VariationFormProps) => {
  const data = form.watch();

  const campaignInputs: FormInput[] = [
    {
      name: "name",
      type: "text",
      placeholder: "Variation name",
      label: () => "Name",
      requiredIf: () => true,
    },
    {
      name: "description",
      type: "text",
      placeholder: "Variation description",
      label: () => "Description",
      requiredIf: () => true,
    },
    {
      name: "weight",
      type: "text",
      placeholder: "Weight",
      label: () => "Variation weight",
      requiredIf: () => true,
      size: 3,
      validationPattern: "[0-9]{1,}",
    },
    {
      name: "isDefault",
      type: "select",
      defaultValue: false,
      options: booleanType,
      label: () => "Is Default",
      requiredIf: () => true,
    },
  ];

  return (
    <>
      {data && data.id && (
        <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
              ID
            </label>
          </div>
          <div className="col-span-2">
            <input
              type="text"
              defaultValue={data.id}
              disabled
              className="block w-full text-gray-500 rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
            />
          </div>
        </div>
      )}
      {campaignInputs.map((input) =>
        !input.showIf || input.showIf(data) ? (
          <div key={input.name} className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {input.label(data)}
              </label>
            </div>
            <div className="col-span-2">
              {input.type === "text" && (
                <input
                  {...form?.register(input.name)}
                  type={input.type}
                  defaultValue={input.defaultValue}
                  step={input.step}
                  size={input.size ? input.size : 255}
                  pattern={input.validationPattern ? input.validationPattern : ".+"}
                  placeholder={input.placeholder}
                  required={input.requiredIf && input.requiredIf(data)}
                  className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                />
              )}
              {input.type === "select" && (
                <select
                  {...form?.register(input.name)}
                  required={input.requiredIf && input.requiredIf(data)}
                  className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                >
                  {input.options?.map((option, index) => (
                    <option key={index} value={option.value} defaultValue={input.defaultValue}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        ) : null
      )}
      <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
            Parameters
          </label>
        </div>
        <div className="col-span-2">
          <VariationParamtersFrom parameters={parameters} form={form} variationParameter={data} />
        </div>
      </div>
    </>
  );
};
