import { faStoreAlt } from "@fortawesome/pro-light-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import { useState } from "react";
import { RestaurantModelType } from "src/app/models/restaurant.model";
import { useHistory } from "react-router-dom";

interface RestaurantDetailsProps {
  restaurant: RestaurantModelType;
}

export const RestaurantDetails = observer((props: RestaurantDetailsProps) => {
  const { restaurant } = props;

  const [showPubKey, setShowPubKey] = useState<boolean>(false);
  const [showPrivKey, setShowPrivKey] = useState<boolean>(false);
  const history = useHistory();
  const goToRestaurantEditPage = (store: RestaurantModelType) => () => {
    const storeId = store.id.toString();
    history.push(`/stores/store/edit/${storeId}`);
  };

  return (
    <div>
      <div className="p-2 md:flex md:items-center md:justify-between md:space-x-4">
        <div className="w-full space-y-6">
          <div className="flex justify-between w-full">
            <h1 className="inset-y-0 left-0 w-4/6 text-2xl font-bold capitalize text-primary">
              <FontAwesomeIcon className="mr-3 text-primary" icon={faStoreAlt} />
              {restaurant.name}
            </h1>
            <button
              className="  ml-2 inset-y-0 right-0 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
              onClick={goToRestaurantEditPage(restaurant)}
            >
              Edit
            </button>
          </div>
          <div className="pt-3 overflow-auto border-t border-gray-200">
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">StoreID (for the API):</span> {restaurant.id}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">ATP Code:</span> {restaurant.atpStoreCode || "N/A"}
              </span>
            </p>

            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Latitude:</span> {restaurant.location.latitude}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Longitude:</span> {restaurant.location.longitude}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Public Key:</span>
                <FontAwesomeIcon
                  className="mx-2 cursor-pointer text-success"
                  icon={showPubKey ? faEyeSlash : faEye}
                  onClick={() => setShowPubKey(!showPubKey)}
                />
                {showPubKey && restaurant.publicKey}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Private Key:</span>
                <FontAwesomeIcon
                  className="mx-2 cursor-pointer text-success"
                  icon={showPrivKey ? faEyeSlash : faEye}
                  onClick={() => setShowPrivKey(!showPrivKey)}
                />
                {showPrivKey && restaurant.privateKey}
              </span>
            </p>
          </div>
          <div className="pt-3 border-t border-gray-200">
            <p className="mt-2 text-xs">
              <span className="font-medium">
                <span className="font-bold">Created:</span>{" "}
                {restaurant.createdAt.toLocaleString({ ...DateTime.DATETIME_MED, month: "long" })}
              </span>
            </p>
            <p className="mt-3 text-xs">
              <span className="font-medium">
                <span className="font-bold">Last Updated:</span>{" "}
                {restaurant.updatedAt.toLocaleString({ ...DateTime.DATETIME_MED, month: "long" })}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
