import { observer } from "mobx-react";
import {
  Classnames,
  DefaultRuleGroupType,
  Field,
  formatQuery,
  parseJsonLogic as baseParseJsonLogic,
  RuleGroupTypeAny,
  ValueEditorProps,
} from "react-querybuilder";
import Select from "react-select";

import {
  ServiceTypeEnum,
  WeatherType
} from "@acrelec-cloud/recommendation-shared";

import { RestaurantsSelector } from "../Restaurants/RestaurantsSelector";

export const queryBuilderFields: Field[] = [
  {
    enableDragAndDrop: true,
    name: "order.serviceType",
    label: "Order Type",
    values: Object.values(ServiceTypeEnum).map((v) => ({ label: v, name: v })) as any,
    valueEditorType: "select",
    operators: [
      { name: "!=", label: "is not" },
      { name: "=", label: "is" },
    ],
  },
  {
    name: "store.id",
    label: "Stores",
    valueEditorType: (operator) => {
      if (operator === "in" || operator === "notIn") {
        return "restaurants" as any;
      }
      return "restaurant" as any;
    },
    operators: [
      { name: "in", label: "in" },
      { name: "notIn", label: "not in" },
    ],
  },
  {
    name: "weather.type",
    label: "Weather",
    values: Object.values(WeatherType).map((v) => ({ label: v, name: v })) as any,
    valueEditorType: "multiselect",
    operators: [
      { name: "in", label: "in" },
      { name: "notIn", label: "not in" },
    ],
  },
  {
    name: "weather.temperature",
    label: "Temperature",
    operators: [
      { name: "<=", label: "less than or equals" },
      { name: ">=", label: "greater than or equals" }
    ],
    validator: (rule) => {
      return !isNaN(Number(rule.value))
    },
    inputType: 'number'
  },
];

function fixNotInOperator(rule: DefaultRuleGroupType, level: number): DefaultRuleGroupType {
  if (rule.rules === undefined) {
    return rule;
  }
  if (rule.not && rule.rules.length === 1) {
    const firstRule: any = rule.rules[0];
    if (firstRule.operator === "in") {
      const newRule = {
        ...firstRule,
        operator: "notIn",
      };
      if (level !== 0) {
        return newRule;
      } else {
        return {
          ...rule,
          not: false,
          rules: [newRule],
        };
      }
    }
  }

  return {
    ...rule,
    rules: rule.rules.map((rule) => fixNotInOperator(rule as any, level + 1)),
  };
}

export function parseJsonLogic(query: any): DefaultRuleGroupType {
  const parsedJsonLogic = baseParseJsonLogic(query, {
    listsAsArrays: true,
  });
  return fixNotInOperator(parsedJsonLogic, 0);
}

export function formatJsonLogic(ruleGroup: RuleGroupTypeAny) {
  return formatQuery(ruleGroup, { format: "jsonlogic", parseNumbers: true } as any);
}

export const tailwindClassNames: Partial<Classnames> = {
  addGroup:
  "ml-2 px-2 py-2 border-none text-xs font-medium rounded shadow-sm text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active",
  addRule:
  "px-2.5 px-2 py-2 border-none text-xs font-medium rounded shadow-sm text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active",
  removeGroup:
  "px-2.5 ml-2 py-2 text-xs font-medium rounded text-primary hover:text-primary-active focus:text-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active",
  removeRule:
  "px-2.5 ml-2 py-2 flex text-xs font-medium rounded text-primary hover:text-primary-active focus:text-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active",
  value: " py-2 ml-1 flex focus:ring-primary focus:border-primary min-w-0 rounded-md sm:text-sm border-divider",
  operators: " py-2 ml-1 flex focus:ring-primary focus:border-primary min-w-0 rounded-md sm:text-sm border-divider",
  fields: "py-2 focus:ring-primary focus:border-primary min-w-0 rounded-md sm:text-sm border-divider",
  combinators: "mt-2 py-2 focus:ring-primary focus:border-primary min-w-0 rounded-md sm:text-sm border-divider",
  rule: "mt-2 flex flex-wrap  min-w-0",
  dragHandle:"flex",
};

export const ValueEditor = observer(
  ({ operator, value, handleOnChange, title, className, type, inputType, values, fieldData, disabled }: ValueEditorProps) => {
    if (operator === "null" || operator === "notNull") {
      return null;
    }
    const placeHolderText = (fieldData && fieldData.placeholder) || "";
    switch (type as any) {
      case "select":
      case "multiselect":
        const options = values
          ? values.map((v) => ({
              value: v.name,
              label: v.label,
            }))
          : [];
        const selectValue = Array.isArray(value)
          ? value.map((v: any) => options.find((option) => option.value === v.toString()))
          : options.find((option) => option.value === value.toString());
        return (
          <Select
            menuPosition="fixed"
            isMulti={type === "multiselect"}
            closeMenuOnSelect
            isDisabled={disabled}
            className={className}
            classNamePrefix="tw-select"
            defaultOptions
            noOptionsMessage={() => "No Results"}
            placeholder={placeHolderText}
            onChange={(values) => {
              if (Array.isArray(values)) {
                handleOnChange(values.map((value) => value.value));
              } else {
                handleOnChange((values as any)?.value || "");
              }
            }}
            value={selectValue}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            options={
              values
                ? values.map((v) => ({
                    value: v.name,
                    label: v.label,
                  }))
                : []
            }
          />
        );

      case "checkbox":
        return (
          <input
            type="checkbox"
            disabled={disabled}
            className={className}
            title={title}
            onChange={(e) => handleOnChange(e.target.checked)}
            checked={!!value}
          />
        );

      case "radio":
        return (
          <span className={className} title={title}>
            {values &&
              values.map((v) => (
                <label key={v.name}>
                  <input
                    disabled={disabled}
                    type="radio"
                    value={v.name}
                    checked={value === v.name}
                    onChange={(e) => handleOnChange(e.target.value)}
                  />
                  {v.label}
                </label>
              ))}
          </span>
        );

      case "restaurant":
      case "restaurants":
        return <RestaurantsSelector isDisabled={ disabled} value={value} onChange={(value) => handleOnChange(value)} isMulti={(type as any) === "restaurants"} className={className} />;

      default:
        return (
          <input
            type={inputType || "text"}
            placeholder={placeHolderText}
            value={value}
            title={title}
            className={className}
            disabled={disabled}
            onChange={(e) => handleOnChange(e.target.value)}
          />
        );
    }
  }
);
