import ExcelJS from "exceljs";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useSdk } from "src/app/hooks/useSdk.hook";
import { saveFile } from "src/app/utils/saveFile.util";

export function useExportStores() {
  const sdk = useSdk();
  const exportStores = useCallback(() => {
    const exportStores = async () => {
      let stores = [];
      let currentPage = 0;
      while (true) {
        const result = await sdk.stores.getAll({
          limit: 100,
          skip: 100 * currentPage,
        });
        if (!result.items.length) {
          break;
        }
        stores.push(...result.items);
        currentPage++;
      }
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Stores");
      sheet.columns = [
        { header: "Name", key: "name", width: 32 },
        { header: "Latitude", key: "latitude", width: 20 },
        { header: "Longitude", key: "longitude", width: 20 },
      ];
      sheet.addRows(
        stores.map((store) => ({
          name: store.name,
          latitude: store.location.latitude,
          longitude: store.location.longitude,
        }))
      );
      const buffer = await workbook.csv.writeBuffer({
        formatterOptions: {
          delimiter: ";",
        },
      });
      await saveFile(buffer.slice(0, buffer.byteLength), "Recommendation stores", "text/csv");
      return stores;
    };
    exportStores()
      .then((stores) => toast.success(`Successfully exported ${stores.length} stores`))
      .catch((e) => {
        console.error(e);
        toast.error(`Error exporting stores`);
      });
  }, [sdk]);
  return exportStores;
}
