export const optionsType = [
  { value: "STRING", label: "STRING" },
  { value: "NUMBER", label: "NUMBER" },
  { value: "BOOLEAN", label: "BOOLEAN" },
  { value: "SELECT", label: "SELECT" },
  { value: "ENGINE", label: "ENGINE" },
];

export const booleanType = [
  { value: true, label: "TRUE" },
  { value: false, label: "FALSE" },
//  { value: "NULL", label: "NULL" },
];

export const engineType : {value: string, label: string}[] = [];
