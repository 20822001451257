import { CreateDashboardRequest, UpdateDashboardRequest } from "@acrelec-cloud/recommendation-shared";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pick, toInteger } from "lodash";
import { observer } from "mobx-react";
import { ElementRef, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { DashboardForm } from "src/app/components/Dashboards/DashboardForm";
import { DataGridDelete } from "src/app/components/DataGrid/DataGridDelete";
import { DataGridForm } from "src/app/components/DataGrid/DataGridForm";
import { DataGridHeader } from "src/app/components/DataGrid/DataGridHeader";
import { DataGridTable } from "src/app/components/DataGrid/DataGridTable";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { Empty } from "src/app/components/Shared/Empty/Empty";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { Pagination } from "src/app/components/Shared/Pagination/Pagination";
import { useStore } from "src/app/contexts/store.context";
import { useDataGrid } from "src/app/hooks/useDataGrid.hook";
import { extractErrorMessage } from "src/app/utils/error";

const columns = [
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Description",
    name: "description",
  },
  {
    label: "",
    name: "actions",
  },
];

export const DashboardsView = observer(() => {
  const { dashboards: dashboardsStore } = useStore();

  const formRef = useRef<ElementRef<typeof DataGridForm>>(null);
  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);
  const history = useHistory();

  const getStartPage = () => {
    if (history.location.state && typeof history.location.state === "object") {
      const from: any = history.location.state;
      if (from.from === "dashboards" && localStorage.getItem("dashboards")) {
        return toInteger(localStorage.getItem("dashboards"));
      }
    }
    return 1;
  };

  const {
    search,
    setSearch,
    entities: dashboards,
    pages,
    currentPage,
    setCurrentPage,
  } = useDataGrid({
    findFunction: dashboardsStore.findDashboards,
    startPage: getStartPage(),
    limit: 20,
  });

  const onPageChange = (pageNumber: number) => {
    localStorage.setItem("dashboards", JSON.stringify(pageNumber));
    setCurrentPage(pageNumber);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const onSubmitForm = async (data: any) => {
    if (!!data.id) {
      const updateDashboardData: UpdateDashboardRequest = {
        ...pick(data, ["name", "description", "theme", "isTitled", "isBordered"]),
      };
      await dashboardsStore
        .updateDashboard(data.id, updateDashboardData)
        .then(() => toast.success(`Successfully updated dashboard '${data.name}' `))
        .catch((e) => toast.error(`Error updating dashboard '${data.name}' : ${extractErrorMessage(e)}`));
    } else {
      const createDashboardData: CreateDashboardRequest = {
        ...pick(data, ["name", "description", "theme", "isTitled", "isBordered"]),
      };
      await dashboardsStore
        .createDashboard(createDashboardData)
        .then(() => toast.success(`Successfully created dashboard '${data.name}' `))
        .catch((e) => toast.error(`Error creating dashboard '${data.name}' : ${extractErrorMessage(e)}`));
    }

    dashboards.reload();
  };

  const onDelete = async (data: any) => {
    await dashboardsStore
      .deleteDashboard(data.id)
      .then(() => toast.success(`Successfully deleted dashboard '${data.name}' `))
      .catch((e) => toast.error(`Error deleting dashboard '${data.name}' : ${extractErrorMessage(e)}`));
    dashboards.reload();
    if (dashboards === undefined || dashboards.data === undefined || dashboards.data?.data.length <= 1)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  useEffect(() => {
    localStorage.removeItem("dashboards");
  }, [history]);

  return (
    <HeaderLayout title="Dashboards">
      <DataGridForm
        ref={formRef}
        title={(form) => {
          const data = form.getValues();
          return data.id ? `Edit a Dashboard` : "Create a Dashboard";
        }}
        onSubmit={onSubmitForm}
      >
        {(form) => <DashboardForm form={form}></DashboardForm>}
      </DataGridForm>
      <DataGridDelete
        ref={deleteRef}
        onDelete={onDelete}
        title="Delete a Dashboard"
        description={(data) => `Are you sure you wish to delete dashboard '${data?.name || ""}' ? This cannot be undone.`}
      />

      <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        {!dashboards.data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
            <DataGridHeader
              searchPlaceholder="Find a Dashboard by name, description..."
              search={search}
              onSearchChange={setSearch}
              actions={
                <>
                  <button
                    onClick={() => formRef.current!.open()}
                    type="button"
                    className="ml-2 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                  >
                    New Dashboard
                  </button>
                </>
              }
            />
            {dashboards.data?.data.length === 0 ? (
              <Empty title="No Dashboards Found" />
            ) : (
              <div className="w-full overflow-x-auto rounded shadow-md">
                <DataGridTable dataProvider={dashboards} columns={columns}>
                  {(data) =>
                    data.data.map((dashboard) => (
                      <tr
                        key={dashboard.id}
                        onClick={() => {
                          localStorage.setItem("dashboards", JSON.stringify(currentPage));
                          return history.push(`/dashboards/${dashboard.id}`);
                        }}
                        className="cursor-pointer hover:bg-secondary"
                      >
                        <td className="px-4 py-3 text-sm font-medium text-info whitespace-nowrap">{dashboard.name}</td>
                        <td className="px-4 py-3 text-sm text-info whitespace-nowrap">{dashboard.description}</td>
                        <td className="flex justify-end px-6 py-3 space-x-3 text-sm font-medium text-left whitespace-nowrap">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteRef.current!.open(dashboard);
                            }}
                            type="button"
                            title="Delete"
                            className="text-error hover:text-error-active"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </DataGridTable>
              </div>
            )}
            {dashboards.data && dashboards.data.data.length !== 0 && (
              <div className="flex items-center justify-between w-full mt-3 mb-12">
                <span className="hidden text-sm sm:block text-info">
                  <b>{dashboards.data.count}</b> results on <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                </span>
                <div>
                  <Pagination currentPage={currentPage} onPageChange={onPageChange} maxPages={pages} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
