import { FindEnginesResponse } from "@acrelec-cloud/recommendation-sdk";
import { CreateEngineRequest, EngineObject, FindEnginesRequest, UpdateEngineRequest } from "@acrelec-cloud/recommendation-shared";
import { cast, flow, getEnv, types } from "mobx-state-tree";
import { EngineModel } from "src/app/models/engine.model";
import { RootSdkType } from "src/app/stores/root.store";
import { createStringEntityReference, createStringEntityStore } from "src/app/utils/string-entity.store";
import { PaginatedModels } from "../models/paginated-models.model";

export const EngineReference = createStringEntityReference("engines", EngineModel);

export const EnginesStore = createStringEntityStore("Engine", EngineModel)
  .props({
    engines: types.maybe(PaginatedModels(EngineReference)),
  })
  .actions((self) => ({
    fetchEntity: flow(function* (engineId: string) {
      const { microservice } = getEnv<RootSdkType>(self);

      const result: EngineObject = yield microservice.engines.getOne(engineId);

      return EngineModel.create({
        rawEngine: result,
        ...(result as any),
      });
    }),
    findEngines: flow(function* (findEnginesData: FindEnginesRequest) {
      const { microservice } = getEnv<RootSdkType>(self);
      const result: FindEnginesResponse = yield microservice.engines.getAll(findEnginesData);

      const engines = result.items.map((engine) => {
        const entityModel = self.cacheEntity(
          EngineModel.create({
            rawEngine: engine,
            ...(engine as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.engines = cast({
        ...result,
        data: cast(engines as any),
      });

      return self.engines as PaginatedModels<typeof EngineModel>;
    }),
    createEngine: flow(function* (createEngineData: CreateEngineRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.engines.create(createEngineData);
      } catch (error) {
        console.error("ERROR CREATING ENGINE:", error);
        throw error;
      }
    }),
    updateEngine: flow(function* (engineId: string, updateEngineData: UpdateEngineRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.engines.update(engineId, updateEngineData);
      } catch (error) {
        console.error("ERROR UPDATING ENGINE:", error);
        throw error;
      }
    }),
    deleteEngine: flow(function* (engineId: string) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.engines.delete(engineId);
      } catch (error) {
        console.error("ERROR DELETING ENGINE:", error);
        throw error;
      }
    }),
  }));

export const enginesStore = EnginesStore.create();
