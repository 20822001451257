import { CreateEngineRequest, UpdateEngineRequest } from "@acrelec-cloud/recommendation-shared";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pick, toInteger } from "lodash";
import { observer } from "mobx-react";
import { ElementRef, useCallback, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { DataGridDelete } from "src/app/components/DataGrid/DataGridDelete";
import { DataGridForm } from "src/app/components/DataGrid/DataGridForm";
import { DataGridHeader } from "src/app/components/DataGrid/DataGridHeader";
import { DataGridTable } from "src/app/components/DataGrid/DataGridTable";
import { EngineForm } from "src/app/components/Engines/EngineForm";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { Empty } from "src/app/components/Shared/Empty/Empty";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { Pagination } from "src/app/components/Shared/Pagination/Pagination";
import { useStore } from "src/app/contexts/store.context";
import { useDataGrid } from "src/app/hooks/useDataGrid.hook";
import { extractErrorMessage } from 'src/app/utils/error';

const columns = [
  {
    label: "Engine Name",
    name: "name",
  },
  {
    label: "URL",
    name: "url",
  },
  {
    label: "",
    name: "actions",
  },
];

export const EnginesView = observer(() => {
  const { engines: enginesStore } = useStore();
  const history = useHistory();

  const getStartPage = () => {
    if (history.location.state && typeof history.location.state === "object") {
      const from: any = history.location.state;
      if (from.from === "engines" && localStorage.getItem("engines")) {
        return toInteger(localStorage.getItem("engines"));
      }
    }
    return 1;
  };

  const {
    search,
    setSearch,
    entities: engines,
    pages,
    currentPage,
    setCurrentPage,
  } = useDataGrid({
    findFunction: enginesStore.findEngines,
    startPage: getStartPage(),
    limit: 20,
  });

  const formRef = useRef<ElementRef<typeof DataGridForm>>(null);
  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  const onEnginePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    localStorage.setItem("engines", JSON.stringify(pageNumber));
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    localStorage.removeItem("engines");
  }, [history, setCurrentPage]);

  const onSubmitForm = useCallback(
    async (data: any) => {
      if (!!data.id) {
        const updateEngineData: UpdateEngineRequest = {
          name: data.name,
          key: data.key,
          description: data.description,
          url: data.url,
        };
        await enginesStore
          .updateEngine(data.id, updateEngineData)
          .then(() => {
            toast.success(`Successfully updated engine '${data.name}' `);
            engines.reload();
          })
          .catch((e) => toast.error(`Error updating engine '${data.name}' : ${extractErrorMessage(e)}`));
      } else {
        const nameSplit = data.name.split(" ");
        const key = nameSplit.join("_").toUpperCase();
        const createEngineData: CreateEngineRequest = {
          ...pick(data, ["name"]),
          key: key,
          description: data.description,
          url: data.url,
        };
        await enginesStore
          .createEngine(createEngineData)
          .then(() => {
            toast.success(`Successfully created engine '${data.name}' `);
            engines.reload();
          })
          .catch((e) => toast.error(`Error creating engine '${data.name}' : ${extractErrorMessage(e)}`));
      }
      engines.reload();
    },
    [enginesStore, engines]
  );

  const onDelete = useCallback(
    async (data: any) => {
      await enginesStore
        .deleteEngine(data.id)
        .then(() => toast.success(`Successfully deleted engine '${data.name}' `))
        .catch((e) => toast.error(`Error deleting engine '${data.name}' : ${extractErrorMessage(e)}`));
      engines.reload();
      if (engines === undefined || engines.data === undefined || engines.data?.data.length <= 1)
        setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
    },
    [enginesStore, engines, setCurrentPage, currentPage]
  );

  return (
    <HeaderLayout title="Engines">
      <DataGridForm
        ref={formRef}
        title={(form) => {
          const data = form.getValues();
          return data.id ? `Edit an Engine` : "Create an Engine";
        }}
        onSubmit={onSubmitForm}
      >
        {(form) => <EngineForm form={form}></EngineForm>}
      </DataGridForm>
      <DataGridDelete
        ref={deleteRef}
        onDelete={onDelete}
        title="Delete an engine"
        description={(data) => `Are you sure you wish to delete engine '${data?.name || ""}' ? This cannot be undone.`}
      />
      <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        {!engines ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
            <DataGridHeader
              searchPlaceholder="Find a Engines by name, url..."
              search={search}
              onSearchChange={setSearch}
              actions={
                <>
                  <button
                    onClick={() => formRef.current!.open()}
                    type="button"
                    className="ml-2 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                  >
                    New Engine
                  </button>
                </>
              }
            />

            {engines.data?.data.length === 0 ? (
              <Empty title="No Engines Found" />
            ) : (
              <div className="w-full overflow-x-auto rounded shadow-md">
                <DataGridTable dataProvider={engines} columns={columns}>
                  {(data) =>
                    data.data.map((engine) => (
                      <tr
                        key={engine.id}
                        onClick={() => {
                          localStorage.setItem("engines", JSON.stringify(currentPage));
                          return history.push(`/engines/${engine.id}`);
                        }}
                        className="cursor-pointer hover:bg-secondary"
                      >
                        <td className="px-4 py-3 text-sm text-info whitespace-nowrap">{engine.name}</td>
                        <td className="px-4 py-3 text-sm text-info whitespace-nowrap">{engine.url}</td>
                        <td className="flex justify-end px-6 py-3 space-x-3 text-sm font-medium text-left x-4 whitespace-nowrap">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteRef.current!.open(engine);
                            }}
                            type="button"
                            title="Delete"
                            className="text-error hover:text-error-active"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </DataGridTable>
              </div>
            )}
            {engines.data && engines.data.data.length !== 0 && (
              <div className="flex items-center justify-between w-full mt-3 mb-12">
                <span className="hidden text-sm sm:block text-info">
                  <b>{engines.data.count}</b> results on <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                </span>
                <div>
                  <Pagination currentPage={currentPage} onPageChange={onEnginePageChange} maxPages={pages} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
