import { DateTime } from 'luxon';
import { types } from 'mobx-state-tree';

export const DateTimeType = types.custom({
  name: 'DateTime',
  isTargetType(value: DateTime) {
    return value instanceof DateTime;
  },
  getValidationMessage(value: any) {
    if (DateTime.fromISO(value).isValid) return '';
    if (value instanceof Date) return '';
    return `'${value}' doesn't look like an ISO Date`;
  },
  fromSnapshot(value: any) {
    if (value instanceof Date) return DateTime.fromJSDate(value);
    return DateTime.fromISO(value);
  },
  toSnapshot(value: DateTime) {
    return value.toISO();
  },
});
