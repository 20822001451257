import { useCallback, useRef, useState } from 'react';
import { UseFormReturn } from "react-hook-form";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";


interface ParameterFormProps {
  form: UseFormReturn;
  optionIndex: number;
  configArray: any[];
  selectDefaultValue: any[];
  setSelectDefaultValue: (array: any[]) => void;
}

interface FormInput {
  name: string;
  type: string;
  step?: string;
  defaultValue?: string;
  placeholder?: string;
  options?: { value: string; label: string }[];
  label: (data: any) => string;
  showIf?: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
}

export const OptionsAccordion = (props: ParameterFormProps) => {
  const { form, optionIndex } = props;
  const data = form.watch();
  const [isOpened, setOpened] = useState<boolean>(false);
  const [height, setHeight] = useState<string>("0px");
  const contentElement = useRef(null);

  const inputs: FormInput[] = [
    {
      name: `config.options.${optionIndex}.label`,
      type: "text",
      placeholder: "Text",
      defaultValue: "NULL",
      label: () => "Name",
      requiredIf: () => true,
    },
    {
      name: `config.options.${optionIndex}.value`,
      type: "text",
      placeholder: "Text",
      defaultValue: "NULL",
      label: () => "Value",
      requiredIf: () => true,
    },
  ];
  const valueRef = useRef<HTMLDivElement>(null);
  
  const executeScroll = () => valueRef?.current?.scrollIntoView({ behavior: "smooth" });;


  const HandleOpening = () => {
    setOpened(!isOpened);
    setHeight(!isOpened ? `150px` : "0px");
    executeScroll();
  };

  const handleChange = useCallback(() => {
     if (data.config && data.config.options) {
       const toFind = form.getValues("defaultValue");

       const findData = data.config.options.find((option : any) => { return option.value === toFind})

       if (findData === undefined) {
        form.setValue("defaultValue",  data.config.options[optionIndex].value);
       }    
      }
  }, [data.config, form, optionIndex]);

  return (
    <div className="w-full my-3 mr-3">
      <div className="border border-gray-700 rounded-md">
        <div className={"border border-b-black rounded-md p-2 flex justify-between text-black"} onClick={HandleOpening}>
          <h4>Option {optionIndex + 1}</h4>
          {isOpened ? <ChevronUpIcon className="w-5 h-5" aria-hidden="true" /> : <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />}
        </div>
        <div ref={contentElement} style={{ height: height }} className="overflow-hidden transition-all duration-200">
          {inputs.map((input) =>
            !input.showIf || input.showIf(data) ? (
              <div key={input.name} className="grid grid-cols-3 gap-4 px-6 py-4">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                    {input.label(data)}
                  </label>
                </div>
                <div className="col-span-2">
                  {input.type === "text" && (
                    <div ref={input.name === `config.options.${optionIndex}.value` ? valueRef : null}>
                      <input
                        {...form.register(input.name)}
                        type={input.type}
                        defaultValue={input.defaultValue}
                        step={input.step}
                        placeholder={input.placeholder}
                        required={input.requiredIf && input.requiredIf(data)}
                        onBlur={handleChange}
                        className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};
