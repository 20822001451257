import React from "react";
import ReactDOM from "react-dom";
import "reflect-metadata";
import { App } from "./app/App";
import "./styles/styles.css";
import "./styles/tailwind.css";

declare global {
  interface Window {
    __RUNTIME_CONFIG__: {
      REACT_APP_API_URL: string;
      REACT_APP_ACRELEC_SSO_URL: string;
      REACT_APP_ACRELEC_SSO_REDIRECT_URL: string;
    };
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
