import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useStore } from "../contexts/store.context";

type ProtectedRouteProps = RouteProps;

export const ProtectedRoute = observer(({ component: Component, ...props }: ProtectedRouteProps) => {
  const {
    authentication: { isAuthenticated, currentUser, fetchCurrentUser, isLoggedIn },
  } = useStore();

  useEffect(() => {
    fetchCurrentUser();
  })

  if (isLoggedIn && !currentUser) {
    return (
      <div className="flex items-center justify-center mt-48">
        <LoadingSpinner type="TailSpin" color="black" height="50" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location },
        }}
      />
    );
  }

  return <Route {...props} />;
});
