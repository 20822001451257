import { faStoreAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import { ParameterModelType } from "src/app/models/parameter.model";
import { useHistory } from "react-router-dom";



interface ParameterDetailsProps {
  parameter: any;
}

export const ParameterDetails = observer((props: ParameterDetailsProps) => {
  const { parameter } = props;
  const history = useHistory();
  const goToParameterEditPage = (parameter: ParameterModelType) => () => {
    history.push(`/parameters/parameter/edit/${parameter.id}`)
  };

  return (
    <div>
      <div className="p-2 md:flex md:items-center md:justify-between md:space-x-4">
        <div className="w-full space-y-6">
          <div className="flex justify-between w-full">
            <h1 className="inset-y-0 left-0 w-4/6 text-2xl font-bold capitalize text-primary">
              <FontAwesomeIcon className="mr-3 text-primary" icon={faStoreAlt} />
              {parameter.name}
            </h1>
            <button
              className="  ml-2 inset-y-0 right-0 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
              onClick={goToParameterEditPage(parameter)}
            >
              Edit
            </button>
          </div>
          <div className="pt-3 overflow-auto border-t border-gray-200">
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">ParameterID (for the API):</span> {parameter.id}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Name:</span> {parameter.name}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Type:</span> {parameter.type}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Description:</span> {parameter.description}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Is active:</span> {parameter.isEnabled === true ? "TRUE" : "FALSE"}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Default value:</span>{" "}
                {typeof parameter.defaultValue === "string" ? parameter.defaultValue : String(parameter.defaultValue).toUpperCase()}
              </span>
            </p>
            {parameter.type === "SELECT" && <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Options:</span>{" "}
                {parameter.config && parameter.config.options && parameter.config.options.map((option: any) => option.label).join(', ')}
              </span>
            </p>}
          </div>
          <div className="pt-3 border-t border-gray-200">
            <p className="mt-2 text-xs">
              <span className="font-medium">
                <span className="font-bold">Created:</span>{" "}
                {parameter.createdAt.toLocaleString({ ...DateTime.DATETIME_MED, month: "long" })}
              </span>
            </p>
            <p className="mt-3 text-xs">
              <span className="font-medium">
                <span className="font-bold">Last Updated:</span>{" "}
                {parameter.updatedAt.toLocaleString({ ...DateTime.DATETIME_MED, month: "long" })}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
