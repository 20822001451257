import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";
import { StoreProvider } from "./contexts/store.context";
import { GlobalRouter } from "./routers/GlobalRouter";

function ErrorFallback(props: any) {
  const { error, resetErrorBoundary } = props;
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
export const App = () => (
  <StoreProvider>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <GlobalRouter />
      <Toaster
        position="bottom-right"
        reverseOrder={true}
        toastOptions={{
          // Define default options
          className: "",
          duration: 2500,
          error: { duration: 5000 },
        }}
      />
    </ErrorBoundary>
  </StoreProvider>
);
