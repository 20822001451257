import { Instance, types } from "mobx-state-tree";
import { StringBaseEntity } from '../utils/string-entity.store';

import { DateTimeType } from "./date-time.custom";

export const EngineModel = StringBaseEntity.named("EngineModel")
  .props({
    id: types.identifier,
    key: types.string,
    name: types.string,
    description: types.string,
    url: types.string,
    createdAt: DateTimeType,
    updatedAt: DateTimeType,
  })
  .actions((self) => ({}))
  .views((self) => ({}))
  .actions((self) => ({}));

export type EngineModelType = Instance<typeof EngineModel>;
