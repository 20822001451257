import { Instance, types } from "mobx-state-tree";
import { NumberBaseEntity } from "../utils/number-entity.store";

import { DateTimeType } from "./date-time.custom";

export const LocationModel = NumberBaseEntity.named("LocationModel").props({
  id: types.maybe(types.identifierNumber),
  latitude: types.number,
  longitude: types.number,
});

export const RestaurantModel = NumberBaseEntity.named("RestaurantModel")
  .props({
    id: types.identifierNumber,
    atpStoreCode: types.maybeNull(types.string),
    name: types.string,
    publicKey: types.maybe(types.string),
    privateKey: types.maybe(types.string),
    location: types.frozen(LocationModel),
    createdAt: DateTimeType,
    updatedAt: DateTimeType,
  })
  .actions((self) => ({}))
  .views((self) => ({}))
  .actions((self) => ({}));

export type RestaurantModelType = Instance<typeof RestaurantModel>;
