import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { map } from "lodash";
import { forwardRef, Fragment, ReactNode, useCallback, useImperativeHandle, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import { extractErrorMessage } from "src/app/utils/error";

interface DataGridFormProps {
  title: ((form: UseFormReturn) => string) | string;
  showCta?: boolean;
  defaultValues?: any;
  okLabel?: string;
  onSubmit: (data: any) => Promise<void>;
  children: (form: UseFormReturn) => ReactNode;
  valueInjectFilters?: { [index: string]: (v: any) => string | number | boolean };
}

interface DataGridFormHandle {
  isOpen: boolean;
  open(data?: any): void;
  reset(): void;
  values: any;
}

export const DataGridForm = forwardRef<DataGridFormHandle, DataGridFormProps>((props, ref) => {
  const { children, onSubmit: onSubmitForm, title, defaultValues, showCta = true, okLabel, valueInjectFilters = {} } = props;
  const [isOpen, setOpen] = useState(false);
  const form = useForm({
    defaultValues: defaultValues || {},
  });
  const titleString = title && (typeof title === "string" ? title : title(form));

  const onSubmit = useCallback(
    (data, e) => {
      e.stopPropagation();
      onSubmitForm(form.getValues())
        .then(() => {
          setOpen(false);
        })
        .catch((error) => {
          toast.error(`Error '${extractErrorMessage(error)}'`);
        });
    },
    [form, onSubmitForm]
  );

  useImperativeHandle(ref, () => ({
    get values() {
      return form.getValues();
    },
    reset() {
      return form.reset();
    },
    open(data?: any) {
      form.reset();
      if (data) {
        map(data, (value: any, key: string) => {
          form.setValue(key, valueInjectFilters[key] ? valueInjectFilters[key](value) : value, { shouldDirty: true });
        });
      }
      setOpen(true);
    },
    get isOpen() {
      return isOpen;
    },
  }));

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 z-50 overflow-y-auto" open={isOpen} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen text-center sm:px-4 sm:pt-4 sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-black bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-screen max-w-2xl text-left align-bottom transition-all transform bg-white sm:rounded-md sm:align-middle sm:w-full ">
              <form className="flex flex-col h-full overflow-y-auto bg-white" onSubmitCapture={form.handleSubmit(onSubmit)}>
                <div className="flex-1">
                  <div className="px-4 py-6 bg-background-header sm:px-6">
                    <div className="flex items-start justify-between space-x-3">
                      <div className="space-y-1">
                        <Dialog.Title className="text-lg font-medium text-primary">{titleString}</Dialog.Title>
                      </div>
                      <div className="flex items-center h-7">
                        <button
                          type="button"
                          className="w-6 h-6 rounded-md text-info hover:text-info-active focus:outline-none focus:ring-2 focus:ring-info"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close</span>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Divider container */}
                  <div>{children(form)}</div>
                </div>

                {/* Action buttons */}
                <div className="flex-shrink-0 px-2 py-3 border-t border-divider sm:px-6">
                  <div className="flex justify-between space-x-3">
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium bg-white rounded-md text-info hover:bg-white-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    {showCta && (
                      <button
                        type="submit"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      >
                        {okLabel || "Save"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
});
