import {
  CreateParameterRequest,
  GetParametersRequest,
  ParameterObject,
  UpdateParameterRequest,
} from "@acrelec-cloud/recommendation-shared";
import { cast, flow, getEnv, types } from "mobx-state-tree";
import { ParameterModel } from "src/app/models/parameter.model";
import { RootSdkType } from "src/app/stores/root.store";
import { createStringEntityReference, createStringEntityStore } from "src/app/utils/string-entity.store";
import { PaginatedModels } from "../models/paginated-models.model";

export interface FindParametersResponse {
  items: ParameterObject[];
  count: number;
}

export const ParameterReference = createStringEntityReference("parameters", ParameterModel);

export const ParametersStore = createStringEntityStore("Parameter", ParameterModel)
  .props({
    parameters: types.maybe(PaginatedModels(ParameterReference)),
  })
  .actions((self) => ({
    fetchEntity: flow(function* (parameterId: string) {
      const { microservice } = getEnv<RootSdkType>(self);

      const result: ParameterObject = yield microservice.parameters.getOne(parameterId);
      return ParameterModel.create({
        rawParameter: result,
        ...(result as any),
      });
    }),
    findParameters: flow(function* (findStoresData: GetParametersRequest) {
      const { microservice } = getEnv<RootSdkType>(self);
      const result: FindParametersResponse = yield microservice.parameters.getAll(findStoresData);

      const parameters = result.items.map((parameter) => {
        const entityModel = self.cacheEntity(
          ParameterModel.create({
            rawParameter: parameter,
            ...(parameter as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.parameters = cast({
        ...result,
        data: cast(parameters as any),
      });
      return self.parameters as PaginatedModels<typeof ParameterModel>;
    }),
    createParameter: flow(function* (createParameterData: CreateParameterRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.parameters.create(createParameterData);
      } catch (error) {
        console.error("ERROR CREATING PARAMETER:", error);
        throw error;
      }
    }),
    updateParameter: flow(function* (parameterId: string, updateParameterData: UpdateParameterRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.parameters.update(parameterId, updateParameterData);
      } catch (error) {
        console.error("ERROR UPDATING PARAMETER:", error);
        throw error;
      }
    }),
    deleteParameter: flow(function* (parameterId: string) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.parameters.delete(parameterId);
      } catch (error) {
        console.error("ERROR DELETING PARAMETER:", error);
        throw error;
      }
    }),
  }));

export const parametersStore = ParametersStore.create();
