import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { nanoid } from 'nanoid';
import React, { useCallback } from 'react';

import styles from './Pagination.module.css';

interface PaginationProps {
  currentPage: number;
  maxPages: number;
  onPageChange: (page: number) => void;
}

const displayIndexes = (currentPage: number, maxPages: number) => {
  const indexes = [];

  const MAX_SLOTS = 7;
  const PRIMARY_INDEXES = 4;
  const INCOMING_SLOT_1 = 6;
  const END_INDEXES = maxPages - 6;
  const INCOMING_SLOT_2 = maxPages - 5;

  if (currentPage <= PRIMARY_INDEXES) {
    // First pages disposition
    for (let i = 1; i <= MAX_SLOTS && i <= maxPages; i++) {
      i === MAX_SLOTS ? indexes.push(maxPages) : i === INCOMING_SLOT_1 ? indexes.push(0) : indexes.push(i);
    }
  } else {
    if (maxPages - currentPage <= 3) {
      // End pages disposition
      for (let i = END_INDEXES; i <= maxPages; i++) {
        i === END_INDEXES ? indexes.push(1) : i === INCOMING_SLOT_2 ? indexes.push(0) : indexes.push(i);
      }
    } else {
      // Middle pages disposition
      indexes.push(1, 0, currentPage - 1, currentPage, currentPage + 1, 0, maxPages);
    }
  }
  return indexes;
};

export const Pagination = ({ currentPage, maxPages, onPageChange }: PaginationProps) => {
  const focusChange = useCallback(
    (newValue: number) => {
      onPageChange(newValue);
    },
    [onPageChange],
  );

  const previousPage = useCallback(() => {
    if (currentPage !== 1) void focusChange(currentPage - 1);
  }, [focusChange, currentPage]);

  const nextPage = useCallback(() => {
    if (currentPage !== maxPages) void focusChange(currentPage + 1);
  }, [focusChange, currentPage, maxPages]);

  return (
    <div className={styles.Nav__Content}>
        <div>
          <nav className={styles.Nav__Pagination} aria-label="Pagination">
            <button onClick={previousPage} className={styles.Nav__Previous__Btn}>
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
            </button>

            {displayIndexes(currentPage, maxPages).map((index: number) => (
              <button
                key={nanoid(10)}
                onClick={() => index && focusChange(index)}
                className={index === currentPage ? styles.Index__Current : styles.Index__Available}
              >
                {index ? index : '...'}
              </button>
            ))}

            <button onClick={nextPage} className={styles.Nav__Next__Btn}>
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
  );
};
