import { useMemo, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { DataGridForm } from "src/app/components/DataGrid/DataGridForm";
import { VariationForm } from "./VariationForm";
import { cloneDeep } from "lodash";

interface VariationAccordionProps {
  form?: UseFormReturn;
  data?: any;
  optionIndex: number;
  parameters: any;
  variation: any;
  onVariationDelete: (variation: any) => void;
  variationFormRef: any;
  onSubmitVariationForm: (data: any) => Promise<void>;
}

interface FormInput {
  name: string;
  type: string;
  step?: string;
  defaultValue?: string;
  placeholder?: string;
  options?: { value: string; label: string }[];
  label: (data: any) => string;
  showIf?: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
}

export const VariationAccordionForm = (props: VariationAccordionProps) => {
  const {
    form,
    optionIndex,
    parameters,
    variation,
    onVariationDelete,
    variationFormRef,
    onSubmitVariationForm,
    data: dataProp,
  } = props;
  const data = form ? form.watch() : dataProp;
  const [isOpened, setOpened] = useState<boolean>(false);
  const [height, setHeight] = useState<string>("0px");
  const contentElement = useRef(null);
  const descriptionDefaultValue = variation.description;
  const weightDefaultValue = variation.weight;
  const valueDefaultValue = variation.isDefault;

  const inputs: FormInput[] = [
    {
      name: `configName${optionIndex}`,
      type: "text",
      placeholder: "Text",
      defaultValue: variation.name,
      label: () => "Name",
      requiredIf: () => true,
    },
    {
      name: `description${optionIndex}`,
      type: "text",
      placeholder: "Variation description",
      defaultValue: descriptionDefaultValue,
      label: () => "Description",
      requiredIf: () => true,
    },
    {
      name: `weight${optionIndex}`,
      type: "text",
      placeholder: "Weight",
      defaultValue: weightDefaultValue,
      label: () => "Variation weight",
      requiredIf: () => true,
    },
    {
      name: `isDefault${optionIndex}`,
      type: "text",
      placeholder: "default value",
      defaultValue: valueDefaultValue,
      label: () => "Is Default",
      requiredIf: () => true,
    },
  ];

  const paremeterInput: FormInput[] = useMemo(() => {
    const ar: any[] = [];

    parameters.forEach((param: any) => {
      if (variation.values && variation.values.hasOwnProperty(param.id)) {
        ar.push({
          name: `param${param.id}`,
          type: "text",
          placeholder: param.name,
          defaultValue: variation.values[param.id],
          label: () => param.name,
        });
      } else {
        const value = param.value ?  param.value : "";
        ar.push({
          name: `param${param.id}`,
          type: "text",
          placeholder: param.name,
          defaultValue: value,
          label: () => param.name,
        });
      }
    });
    return ar;
  }, [variation, parameters]);

  const HandleOpening = () => {
    setOpened(!isOpened);
    setHeight(!isOpened ? `100%` : "0px");
  };

  return (
    <div className="w-full my-3">
      <div className="border border-gray-700 rounded-md">
        <div
          className={
            "border border-b-black rounded-md p-2 flex justify-between text-black"
          }
          onClick={HandleOpening}
        >
          <h4>Variation : {variation.name}</h4>
          {isOpened ? (
            <ChevronUpIcon className="w-5 h-5" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          )}
        </div>
        <div
          ref={contentElement}
          style={{ height: height }}
          className="overflow-hidden transition-all duration-200"
        >
          <DataGridForm
            ref={variationFormRef}
            title={(form) => {
              const data = form.getValues();
              return data.id ? `Edit a Variation` : "Create a Variation";
            }}
            onSubmit={onSubmitVariationForm}
            valueInjectFilters={{
              condition: (v: Object) => JSON.stringify(v, undefined, 2),
            }}
          >
            {(form) => <VariationForm form={form} parameters={parameters} />}
          </DataGridForm>
          {inputs.map((input) =>
            !input.showIf || input.showIf(data) ? (
              <div key={input.name}>
                <div className="grid grid-cols-3 gap-1 px-2 py-3">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      {input.label(data)}
                    </label>
                  </div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      disabled
                      value={input.defaultValue}
                      className="block w-full text-gray-500 rounded-md border-divider sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            ) : null
          )}
          <div className="grid grid-cols-3 gap-1 px-2 py-3">
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
              >
                Parameters
              </label>
            </div>
            <div className="w-full col-span-2">
              {paremeterInput.map((input) =>
                !input.showIf || input.showIf(data) ? (
                  <div key={input.name}>
                    <div className="grid grid-cols-3 gap-1 px-2 py-3">
                      <div>
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          {input.label(data)}
                        </label>
                      </div>
                      <div className="col-span-2">
                        <input
                          type="text"
                          disabled
                          value={input.defaultValue}
                          className="block w-full text-gray-500 rounded-md border-divider sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </div>
          <div className="px-3 py-4">
            <button
              onClick={(e) => {
                e.stopPropagation();
                variationFormRef.current!.open(cloneDeep(variation));
              }}
              type="button"
              title="Edit"
              className="px-3 py-3 text-primary hover:text-primary-active"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onVariationDelete(variation);
              }}
              type="button"
              title="Delete"
              className="px-3 py-3 text-primary hover:text-primary-active"
            >
              <div className="ml-3">
                <FontAwesomeIcon className="w-5 h-5" icon={faTrashAlt} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
