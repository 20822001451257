import classnames from "clsx";
import React, { useCallback, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useStore } from "../../contexts/store.context";
import { UserDropdown } from "./UserDropdown";
import { observer } from "mobx-react";
import OptimizeLogo from "../../../assets/images/optimize_white_logo.png";

const NAV_LINKS = [
  { name: "Reporting", to: "/dashboards" },
  { name: "Stores", to: "/stores" },
  { name: "Engines", to: "/engines" },
  { name: "Parameters", to: "/parameters" },
  { name: "Campaigns", to: "/campaigns" },
];

export const AdminHeader = observer(() => {
  const {
    authentication: { logout },
  } = useStore();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onSwitchOpen = useCallback(() => {
    setMenuOpen(!isMenuOpen);
  }, [setMenuOpen, isMenuOpen]);

  return (
    <nav className="z-30 bg-header">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center h-full">
            <div className="items-center flex-shrink-0 h-full py-2">
              <Link to="/dashboards" className="h-full">
                <img className="h-full" src={OptimizeLogo} alt="Optimize" />
              </Link>
            </div>
            <div className="hidden md:block">
              <div className="flex items-baseline ml-10 space-x-1">
                {NAV_LINKS.map((link) => (
                  <NavLink
                    key={link.to}
                    to={link.to}
                    className="px-3 py-2 text-sm font-medium text-white rounded-sm hover:border-b-2 focus:outline-none"
                    activeClassName="border-b-2"
                  >
                    {link.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="flex items-center ml-4 md:ml-6">
              <div className="relative ml-3">
                <UserDropdown />
              </div>
            </div>
          </div>
          <div className="flex -mr-2 md:hidden">
            <button
              className="px-3 py-2 text-sm font-medium text-white rounded-md hover:ring-white hover:ring-1 focus:ring-1 focus:outline-none focus:text-white focus:ring-white hover:text-white"
              type="button"
              onClick={onSwitchOpen}
            >
              <svg className="block w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>

              <svg className="hidden w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={classnames("md:hidden", { block: isMenuOpen, hidden: !isMenuOpen })}>
        {NAV_LINKS.map((link) => (
          <div key={link.to} className="flex px-2 pt-1 pb-1 space-y-1 sm:px-3">
            <NavLink
              exact
              to={link.to}
              className="flex-1 px-3 py-2 text-sm font-medium text-white hover:ring-white hover:ring-1 focus:ring-1 focus:outline-none focus:text-white focus:ring-white hover:text-white"
              activeClassName="ring-white ring-1 text-white"
            >
              {link.name}
            </NavLink>
          </div>
        ))}
        <div className="pb-2 mt-1 border-t border-white">
          <div className="px-2 mt-2 space-y-1">
            <button
              onClick={logout}
              type="button"
              className="w-full px-3 py-2 text-sm font-medium text-left text-white rounded-md hover:ring-white hover:ring-1 focus:ring-1 focus:outline-none focus:text-white focus:ring-white hover:text-white"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
});
