import React from "react";
import { ReactComponent as EmptyLogo } from "../../../../assets/images/empty.svg";

interface EmptyProps {
  title?: string;
}

export const Empty = (props: EmptyProps) => {
  const { title = "No Data" } = props;

  return (
    <div className="flex flex-col items-center justify-center pt-12">
      <EmptyLogo />
      <p className="mt-3 text-sm text-gray-500 capitalize">{title}</p>
    </div>
  );
};
