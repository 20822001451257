import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { useAsync } from "react-async";
import { useHistory, useParams } from "react-router-dom";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { ParameterDetails } from "src/app/components/Parameters/ParameterDetails";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useStore } from "src/app/contexts/store.context";

interface ParameterViewParams {
  parameterId: string;
}

export const ParameterView = () => {
  const history = useHistory();
  const {
    parameters: { getEntityAsync: getParameter },
  } = useStore();

  const { parameterId } = useParams<ParameterViewParams>();

  const fetchParameter = useCallback(async () => {
    return getParameter(parameterId);
  }, [getParameter, parameterId]);

  const parameter = useAsync({
    promiseFn: fetchParameter,
  });

  return (
    <HeaderLayout
      left={
        <button type="button" onClick={() => history.push(parameter.data?.isEnabled ? '/parameters/active' : '/parameters/archived', {from : "parameters"})}>
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          Back
        </button>
      }
      title="Parameters"
    >
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
        {!parameter.data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="flex flex-col w-full px-4 py-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ">
            <div className="xl:grid xl:grid-cols-2">
              <div className="xl:col-span-2 xl:pr-4 xl:border-gray-200">
                <div className="p-4 bg-white rounded shadow-md">
                  <ParameterDetails parameter={parameter.data} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </HeaderLayout>
  );
};
