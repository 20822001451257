import { UseFormReturn } from "react-hook-form";
interface RestaurantFormProps {
  form: UseFormReturn;
}

interface FormInput {
  name: string;
  type: string;
  step?: string;
  placeholder: string;
  options?: { value: string; label: string }[];
  label: (data: any) => string;
  showIf?: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
}

const inputs: FormInput[] = [
  {
    name: "name",
    type: "text",
    placeholder: "Kiosk A",
    label: () => "Name",
    requiredIf: () => true,
  },
  {
    name: "atpStoreCode",
    type: "text",
    placeholder: "001",
    label: () => "ATP Store Code"
  },
  {
    name: "location.latitude",
    type: "number",
    step: "0.000001",
    placeholder: "48.864716",
    label: () => "Latitude",
    requiredIf: (data) => !data.id,
  },
  {
    name: "location.longitude",
    type: "number",
    step: "0.000001",
    placeholder: "2.349014",
    label: () => "Longitude",
    requiredIf: (data) => !data.id,
  },
];

export const RestaurantForm = (props: RestaurantFormProps) => {
  const { form } = props;
  const data = form.watch();

  return (
    <>
      {inputs.map((input) =>
        !input.showIf || input.showIf(data) ? (
          <div key={input.name} className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {input.label(data)}
              </label>
            </div>
            <div className="col-span-2">
              {input.type !== "select" && input.type !== "tags" && (
                <input
                  {...form.register(input.name)}
                  type={input.type}
                  step={input.step}
                  placeholder={input.placeholder}
                  required={input.requiredIf && input.requiredIf(data)}
                  className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                />
              )}
              {input.type === "select" && (
                <select
                  {...form.register(input.name)}
                  required={input.requiredIf && input.requiredIf(data)}
                  className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                >
                  {input.options?.map((option, index) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        ) : null
      )}
    </>
  );
};
