import { observer } from "mobx-react";
import { useCallback } from "react";
import { useAsync } from "react-async";
import Select from "react-select";
import { LoadingSpinner } from 'src/app/components/Shared/LoadingSpinner/LoadingSpinner';
import { useStore } from "src/app/contexts/store.context";

interface ParametersSelectorProps {
  value: string | string[] | null;
  onChange: (value: string | string[] | null) => void;
  isDisabled?: boolean;
  isMulti?: boolean;
}

export const ParametersSelector = observer((props: ParametersSelectorProps) => {
  const { value, onChange, isDisabled = false, isMulti = false } = props;
  const { parameters: parametersStore } = useStore();

  const fetchParameters = useCallback(async () => {
    return parametersStore.findParameters({ limit: 3000, isEnabled: true });
  }, [parametersStore]);

  const parameters = useAsync({
    promiseFn: fetchParameters,
  });

  if (!parameters.data) {
    return <LoadingSpinner type="TailSpin" color="black" height="50" />
  }

  const options = parameters.data.data.map((v) => ({
    value: v.id,
    label: v.name,
  }));

  const selectValue = Array.isArray(value)
    ? value.map((v: any) => options.find((option) => option.value === v.toString())).filter(value => !!value)
    : options.find((option) => option.value === value) || null;

  return (
    <Select
      isMulti={isMulti}
      closeMenuOnSelect
      isDisabled={isDisabled}
      classNamePrefix="tw-select"
      defaultOptions
      noOptionsMessage={() => "No Results"}
      placeholder={"Select parameters"}
      onChange={(values) => {
        if (Array.isArray(values)) {
          onChange(values.map((value) => value.value));
        } else  {
          onChange((values as any)?.value || null);
        }
      }}
      value={selectValue}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
      options={options}
      menuPosition="fixed"
    />
  );
});
