import React from 'react';
import Loader from 'react-loader-spinner';

// If you want to change the Loader type, please refers to the documentation here :
// https://github.com/mhnpd/react-loader-spinner

// If you need more type of loader, please refers to the documentation
type LoadingSpinnerType = 'ThreeDots' | 'Watch' | 'Oval' | 'TailSpin';

interface LoadingSpinnerProps {
  type: LoadingSpinnerType;
  color?: string;
  height?: string;
  width?: string;
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { type, color, height, width } = props;
  return <Loader type={type} color={color} height={height} width={width} />;
};
