import { cast, flow, getEnv, getRoot, Instance, types } from "mobx-state-tree";

export const StringBaseEntity = types.model({
  id: types.identifier,
});

export const createStringEntityStore = <T extends typeof StringBaseEntity>(entityName: string, model: T) => {
  type Model = Instance<T>;

  return types
    .model(`${entityName}Store`, {
      cache: types.optional(types.map(model), () => cast({})),
    })
    .actions((self) => ({
      cacheEntity(entity: Model, force?: boolean) {
        const cachedEntity = self.cache.get(entity.id);
        if (cachedEntity && !force) {
          return cachedEntity as Model;
        }
        self.cache.set(entity.id, entity);
        return entity;
      },
      fetchEntity: async (entityId: string): Promise<Model> => {
        throw new Error("Not implemented");
      },
    }))
    .actions((self) => {
      const inFlightEntities = new Map<string, Promise<Model>>();

      return {
        loadEntityById: flow(function*(entityId: string, forceFetch = false) {
          const formattedEntityId = entityId;

          if (!forceFetch && self.cache.has(formattedEntityId)) {
            return self.cache.get(formattedEntityId) as Model;
          }

          if (inFlightEntities.has(formattedEntityId)) {
            return inFlightEntities.get(formattedEntityId);
          }

          const entityPromise = self.fetchEntity(entityId);

          inFlightEntities.set(formattedEntityId, entityPromise);

          const resolvedEntity = yield entityPromise;

          const addedUser = self.cacheEntity(resolvedEntity, forceFetch);

          inFlightEntities.delete(formattedEntityId);

          return addedUser;
        }),
      };
    })
    .views((self) => {
      return {
        getEntity(userId: string): Model | undefined {
          self.loadEntityById(userId);
          return self.cache.get(userId) as Model | undefined;
        },
        getEntityAsync(userId: string, forceFetch = false): Promise<Model> {
          if (!forceFetch && self.cache.has(userId)) {
            return Promise.resolve(self.cache.get(userId) as Model);
          }
          return self.loadEntityById(userId, forceFetch) as Promise<Model>;
        },
      };
    });
};

export const createStringEntityReference = <T extends typeof StringBaseEntity>(storeIdentifier: string, model: T) => {
  return types.maybeNull(
    types.reference(model, {
      get(identifier, parent: any) {
        if (!identifier && !parent) {
          return;
        }
        const rootStore = getEnv(parent)?.rootStore || getRoot<any>(parent);
        if (!rootStore || !rootStore[storeIdentifier] || !rootStore[storeIdentifier].getEntity) {
          return;
        }
        return rootStore[storeIdentifier].getEntity(identifier);
      },
      set(value) {
        return value ? value.id : (null as any);
      },
    })
  );
};
