import { UserRole } from "@acrelec-cloud/recommendation-shared";
import { Instance, types } from "mobx-state-tree";
import { NumberBaseEntity } from "../utils/number-entity.store";
import { DateTimeType } from "./date-time.custom";

export const UserModel = NumberBaseEntity.named("UserModel")
  .props({
    id: types.identifierNumber,
    rawUser: types.maybe(types.frozen<any>()),
    email: types.string,
    acrelecId: types.maybeNull(types.string),
    role: types.enumeration(Object.values(UserRole)),
    firstName: types.string,
    lastName: types.string,
    resetToken: types.maybe(types.maybeNull(types.string)),
    createdAt: types.maybe(DateTimeType),
    updatedAt: types.maybe(DateTimeType),
  })
  .views((self) => ({
    get fullName() {
      return `${self.firstName} ${self.lastName}`;
    },
  }))
  .actions((self) => ({}));

export type UserModelType = Instance<typeof UserModel>;
