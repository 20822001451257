import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { ConfigurePasswordView } from "src/app/views/Authentication/ForgotPassword/ConfigurePasswordView.view";
import { RequestPasswordView } from "src/app/views/Authentication/ForgotPassword/RequestPasswordView.view";
import { LoginView } from "src/app/views/Authentication/LoginView";
import { CampaignsView } from "src/app/views/Campaigns/CampaignsView";
import { CampaignView } from "src/app/views/Campaigns/CampaignView";
import { DashboardsView } from "src/app/views/Dashboards/DashboardsView";
import { DashboardView } from "src/app/views/Dashboards/DashboardView";
import { EnginesView } from "src/app/views/Engines/EnginesView";
import { EngineView } from "src/app/views/Engines/EngineView";
import { ParametersView } from "src/app/views/Parameters/ParametersView";
import { ParameterView } from "src/app/views/Parameters/ParameterView";
import { RestaurantsView } from "src/app/views/Restaurants/RestaurantsView";
import { RestaurantView } from "src/app/views/Restaurants/RestaurantView";
import { CampaignEdit } from "src/app/views/Campaigns/CampaignEdit";
import { DashboardEdit } from "src/app/views/Dashboards/DashboardEdit";
import { EngineEdit } from "src/app/views/Engines/EngineEdit";
import { ParameterEdit } from "src/app/views/Parameters/ParameterEdit";
import { RestaurantEdit } from "src/app/views/Restaurants/RestaurantEdit";
import { AdminLayout } from "../components/Layout/AdminLayout";
import { ProtectedRoute } from "../containers/ProtectedRoute";
import { useStore } from "../contexts/store.context";
import { NotFoundView } from "../views/NotFound.view";

export const GlobalRouter = observer(() => {
  const {
    authentication: { isLoggedIn, fetchCurrentUser, clearCurrentUser },
  } = useStore();

  useEffect(() => {
    if (isLoggedIn) {
      fetchCurrentUser();
    } else {
      clearCurrentUser();
    }
  }, [isLoggedIn, fetchCurrentUser, clearCurrentUser]);

  return (
    <Router>
      <Switch>
        <Route exact path="/404" component={NotFoundView} />;
        <Route exact path="/login" component={LoginView} />
        <Route exact path="/forgotpassword" component={RequestPasswordView} />
        <Route exact path="/configurepassword/:token" component={ConfigurePasswordView} />
        <ProtectedRoute>
          <AdminLayout>
            <Switch>
              <Redirect exact path="/" to="/dashboards" />
              <Route exact path="/dashboards" component={DashboardsView} />
              <Route exact path="/dashboards/dashboard/edit/:dashboardId" component={DashboardEdit} />
              <Route exact path="/dashboards/:dashboardId" component={DashboardView} />
              <Route exact path="/stores" component={RestaurantsView} />
              <Route exact path="/stores/store/edit/:storeId" component={RestaurantEdit} />
              <Route exact path="/stores/:storeId" component={RestaurantView} />
              <Route exact path="/engines" component={EnginesView} />
              <Route exact path="/engines/engine/edit/:engineId" component={EngineEdit} />
              <Route exact path="/engines/:engineId" component={EngineView} />
              <Route exact path="/parameters" component={ParametersView} />
              <Route exact path="/parameters/:mode" component={ParametersView} />
              <Route exact path="/parameters/parameter/edit/:parameterId" component={ParameterEdit} />
              <Route exact path="/parameters/parameter/:parameterId" component={ParameterView} />
              <Route exact path="/campaigns/:mode" component={CampaignsView} />
              <Route exact path="/campaigns" component={CampaignsView} />
              <Route exact path="/campaigns/campaign/edit/:campaignId" component={CampaignEdit} />
              <Route exact path="/campaigns/campaign/:campaignId" component={CampaignView} />
              <Redirect to="/404" />
            </Switch>
          </AdminLayout>
        </ProtectedRoute>
      </Switch>
    </Router>
  );
});
