import { UpdateDashboardRequest } from "@acrelec-cloud/recommendation-shared";
import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { faChartPie, faCog } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pick } from "lodash";
import { observer } from "mobx-react";
import { ElementRef, useCallback, useRef } from "react";
import { useAsync } from "react-async";
import { useHistory, useParams } from "react-router-dom";
import { DashboardForm } from "src/app/components/Dashboards/DashboardForm";
import { DataGridForm } from "src/app/components/DataGrid/DataGridForm";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useStore } from "src/app/contexts/store.context";
import { HeaderLayout } from "../../components/Layout/HeaderLayout";


interface DashboardViewParams {
  dashboardId: string;
}

export const DashboardView = observer(() => {
  const {
    dashboards: { getEntityAsync: getDashboard, updateDashboard },
  } = useStore();
  const history = useHistory();
  const formRef = useRef<ElementRef<typeof DataGridForm>>(null);

  const { dashboardId } = useParams<DashboardViewParams>();

  const fetchDashboard = useCallback(async () => {
    return getDashboard(+dashboardId, true);
  }, [getDashboard, dashboardId]);

  const dashboard = useAsync({
    promiseFn: fetchDashboard,
  });

  const onSubmitForm = async (data: any) => {
    const updateDashboardData: UpdateDashboardRequest = {
      ...pick(data, ["name", "description", "theme", "isTitled", "isBordered"]),
    };
    await updateDashboard(data.id, updateDashboardData);

    dashboard.reload();
  };

  const goToDashboardEditPage = () => {
    history.push(`/dashboards/dashboard/edit/${dashboardId}`);
  };

  return (
    <HeaderLayout
      left={
        <button type="button" onClick={() => history.push("/dashboards", { from: "dashboards" })}>
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          Back
        </button>
      }
      title="Dashboards"
    >
      <DataGridForm
        ref={formRef}
        title={(form) => {
          const data = form.getValues();
          return data.id ? `Edit a Dashboard` : "Create a Dashboard";
        }}
        onSubmit={onSubmitForm}
      >
        {(form) => <DashboardForm form={form}></DashboardForm>}
      </DataGridForm>
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
        {!dashboard.data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="flex flex-col w-full h-full px-1 mx-auto mb-3 sm:px-6 lg:px-8 max-w-7xl">
            <div className="flex justify-end w-full px-2 py-3 md:px-0">
              <button
                onClick={goToDashboardEditPage}
                className="inline-flex items-center px-4 py-2.5 text-xs font-medium text-white border border-transparent rounded shadow-md md:mr-2 bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
              >
                <div className="space-x-1">
                  <span>Edit Details</span>
                  <FontAwesomeIcon icon={faCog} />
                </div>
              </button>
              <button className="inline-flex items-center px-4 py-2.5 text-xs font-medium text-white border border-transparent rounded shadow-md md:mr-2 bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active">
                <a
                  rel="noreferrer"
                  className="space-x-1"
                  target="_blank"
                  href={
                    `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/metabase/authenticate?redirect=/dashboard/${dashboard.data.metabaseDashboardId}`
                  }
                >
                  <span>Edit Data</span>
                  <FontAwesomeIcon icon={faChartPie} />
                </a>
              </button>
            </div>
            <iframe src={dashboard.data.embedUrl} title="metabase" frameBorder={0} width="100%" height="100%" allowTransparency />
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
