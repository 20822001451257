
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useQuery } from "src/app/hooks/useQuery.hook";
import OptimizeLogo from "../../../assets/images/optimize_black_logo.png";
import { useStore } from "../../contexts/store.context";

type LoginFormData = {
  email: string;
  password: string;
};

export const LoginView = observer(() => {
  const {
    authentication: { currentUser, authenticate, ssoAuthenticate, logout },
  } = useStore();

  const history = useHistory();
  const route = useLocation<any>();
  const query = useQuery();

  const [isLoading, setIsLoading] = useState<boolean>(!!query.get("code"));

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = useForm<LoginFormData>();

  useEffect(() => {
    const code = query.get("code");
    const nonce = localStorage.getItem("nonce");

    if (!code || !nonce) return;

    setIsLoading(true);
    ssoAuthenticate(code)
      .then(() => {})
      .catch(() => {
        logout();
        setIsLoading(false);
      });
  }, [query, history, ssoAuthenticate, logout]);

  useEffect(() => {
    if (currentUser) setIsLoading(false);
  }, [currentUser]);

  const onSubmit = (data: LoginFormData) => {
    authenticate(data).catch((error: any) => {
      const { statusCode, message } = error.response.data;
      if (statusCode === 401) {
        if (message === "Invalid password") {
          setError("password", {
            type: "server",
            message: "Invalid password",
          });
          return;
        }
        setError("email", {
          type: "server",
          message: "Invalid e-mail",
        });
      }
    });
  };

  const redirectToAtpSso = async () => {
    const nonce = Math.random().toString(36).substring(2, 10);
    localStorage.setItem("nonce", nonce);

    setIsLoading(true);

    const redirectUrl = "redirectUrl=" + window.location.protocol + "//" + window.location.host + "/login";

    window.location.replace(`${window.__RUNTIME_CONFIG__.REACT_APP_ACRELEC_SSO_URL}/authenticate?${redirectUrl}`);
  };

  useEffect(() => {
    if (currentUser) {
      history.push("/dashboards");
    }
  }, [currentUser, history, route]);

  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-divider-light sm:px-6 lg:px-8">
      {isLoading && (
        <div className="flex items-center justify-center">
          <LoadingSpinner type="TailSpin" color="black" height="50" />
        </div>
      )}
      {!isLoading && (
        <div className="w-full max-w-md space-y-8">
          <div className="flex flex-col items-center justify-center">
            <img className="w-full" src={OptimizeLogo} alt="Optimize" />
            <h1 className="text-xl">Recommendation Engine Admin Portal</h1>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="space-y-4">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  E-mail
                </label>
                <input
                  id="email-address"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full px-3 py-2 border rounded-md appearance-none text-info border-divider rounded-t-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                  {...register("email")}
                  placeholder="E-mail"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full px-3 py-2 border rounded-md appearance-none text-info border-divider rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                  {...register("password")}
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="space-y-8 flex flex-col">
              <button
                type="submit"
                className="relative w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-primary group hover:brightness-125 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                Sign In
              </button>
              <button
                type="button"
                onClick={redirectToAtpSso}
                className="relative w-4/5 px-4 py-4 self-center text-sm font-medium text-white border border-transparent rounded-md bg-primary group hover:brightness-125 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                Use my Company SSO
              </button>
              <p className="w-full h-3 p-3 text-xs text-center text-error">
                {errors.email && errors.email.message}
                {errors.password && errors.password.message}
              </p>
            </div>
          </form>
        </div>
      )}
    </div>
  );
});
