import { cast, flow, getEnv, types } from "mobx-state-tree";

import { CampaignObject, CreateCampaignRequest, GetCampaignsRequest, PaginatedResult, UpdateCampaignRequest } from "@acrelec-cloud/recommendation-shared";

import { CampaignModel } from "../models/campaign.model";
import { PaginatedModels } from "../models/paginated-models.model";
import { RootSdkType } from "../stores/root.store";
import { createStringEntityReference, createStringEntityStore } from "../utils/string-entity.store";
import { formatJsonLogic, parseJsonLogic } from "../components/Campaigns/QueryEditor";

export const CampaignReference = createStringEntityReference("campaigns", CampaignModel);

export const CampaignsStore = createStringEntityStore("Campaign", CampaignModel)
  .props({
    campaigns: types.maybe(PaginatedModels(CampaignReference)),
  })
  .actions((self) => ({
    fetchEntity: flow(function* (campaignId: string) {
      const { microservice } = getEnv<RootSdkType>(self);

      const result: CampaignObject = yield microservice.campaigns.getOne(campaignId.toString());

      return CampaignModel.create({
        rawCampaign: result,
        ...(result as any),
        condition: parseJsonLogic(result.condition)
      });
    }),
    findCampaigns: flow(function* (findStoresData: GetCampaignsRequest) {
      const { microservice } = getEnv<RootSdkType>(self);
      const result: PaginatedResult<CampaignObject> = yield microservice.campaigns.getAll(findStoresData);

      const campaigns = result.items.map((campaign) => {
        const entityModel = self.cacheEntity(
          CampaignModel.create({
            rawCampaign: campaign,
            ...(campaign as any),
            condition: parseJsonLogic(campaign.condition)
          }),
          true
        );
        return entityModel.id;
      });

      self.campaigns = cast({
        ...result,
        data: cast(campaigns as any),
      });

      return self.campaigns as PaginatedModels<typeof CampaignModel>;
    }),
    createCampaign: flow(function* (createCampaignData: CreateCampaignRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.campaigns.create({
          ...createCampaignData,
          condition: formatJsonLogic(createCampaignData.condition as any) as any || {}
        });
      } catch (error) {
        console.error("ERROR CREATING STORE:", error);
        throw error;
      }
    }),
    updateCampaign: flow(function* (campaignId, updateCampaignData: UpdateCampaignRequest) {
      const { microservice } = getEnv<RootSdkType>(self);
      try {
        const data = { ...updateCampaignData };
        if (updateCampaignData.condition) {
          data.condition = formatJsonLogic(updateCampaignData.condition as any) as any || {};
        }

        return yield microservice.campaigns.update(campaignId, data);
      } catch (error) {
        console.error("ERROR UPDATING STORE:", error);
        throw error;
      }
    }),
    deleteCampaign: flow(function* (campaignId: number) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.campaigns.delete(campaignId.toString());
      } catch (error) {
        console.error("ERROR DELETING STORE:", error);
        throw error;
      }
    }),
  }));

export const campaignsStore = CampaignsStore.create();
