import { CreateCampaignRequest, UpdateCampaignRequest } from "@acrelec-cloud/recommendation-shared";
import { faArchive, faTrashAlt, faUndo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { pick, toInteger } from "lodash";
import { observer } from "mobx-react";
import { ElementRef, useCallback, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CampaignForm } from "src/app/components/Campaigns/CampaignForm";
import { DataGridArchive } from "src/app/components/DataGrid/DataGridArchive";
import { DataGridDelete } from "src/app/components/DataGrid/DataGridDelete";
import { DataGridForm } from "src/app/components/DataGrid/DataGridForm";
import { DataGridHeader } from "src/app/components/DataGrid/DataGridHeader";
import { DataGridTable } from "src/app/components/DataGrid/DataGridTable";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { Empty } from "src/app/components/Shared/Empty/Empty";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { Pagination } from "src/app/components/Shared/Pagination/Pagination";
import { useStore } from "src/app/contexts/store.context";
import { useDataGrid } from "src/app/hooks/useDataGrid.hook";
import { CampaignModelType } from "src/app/models/campaign.model";
import { extractErrorMessage } from 'src/app/utils/error';

const columns = [
  {
    label: "Campaign name",
    name: "campaignname",
  },
  {
    label: "",
    name: "actions",
  },
];

interface CampaignsViewParams {
  mode: string;
}

export const CampaignsView = observer(() => {
  const { mode } = useParams<CampaignsViewParams>();
  const { campaigns: campaignsStore } = useStore();
  const { parameters: parametersStore } = useStore();
  const { deleteCampaign, updateCampaign } = campaignsStore;
  const { entities: parameters } = useDataGrid({
    findFunction: parametersStore.findParameters,
    params: {
      isEnabled: true,
    },
  });
  const archivedCampaignsOnly = mode === "archived";
  const history = useHistory();

  const getStartPage = () => {
    if (history.location.state && typeof history.location.state === "object") {
      const from: any = history.location.state;
      if (from.from === "campaigns" && localStorage.getItem("campaigns")) {
        return toInteger(localStorage.getItem("campaigns"));
      }
    }
    return 1;
  };

  const {
    search,
    setSearch,
    entities: campaigns,
    pages,
    currentPage,
    setCurrentPage,
  } = useDataGrid({
    findFunction: campaignsStore.findCampaigns,
    startPage: getStartPage(),
    params: {
      isEnabled: !archivedCampaignsOnly,
    },
  });

  const formRef = useRef<ElementRef<typeof DataGridForm>>(null);
  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);
  const archiveRef = useRef<ElementRef<typeof DataGridArchive>>(null);

  const onEnable = async (data: any) => {
    const request = new UpdateCampaignRequest();
    request.isEnabled = true;
    await updateCampaign(data.id, request);
    toast(`Campaign '${data.name}' activated !`);
    campaigns.reload();
    if (campaigns === undefined || campaigns.data === undefined || campaigns.data?.data.length <= 1)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const onCampaignPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);

    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const location = useLocation();

  useEffect(() => {
    localStorage.removeItem("campaigns");
  }, [history, location]);

  const onSubmitForm = useCallback(
    async (data: any) => {
      if (!!data.id) {
        const updateCampaignData: UpdateCampaignRequest = {
          name: data.name,
          description: data.description,
          isEnabled: data.isEnabled,
          condition: data.condition,
          parameters: data.parameters.filter((item: boolean | string) => item !== false) || [],
        };
        await campaignsStore
          .updateCampaign(data.id, updateCampaignData)
          .catch((e) => toast.error(`Error updating campaign '${data.name}' : ${extractErrorMessage(e)}`))
          .then(() => {
            toast.success(`Campaign '${data.name}' successfully updated`);
            campaigns.reload();
          });
      } else {
        const createRestaurantData: CreateCampaignRequest = {
          ...pick(data, ["name"]),
          description: data.description,
          isEnabled: data.isEnabled,
          condition: data.condition,
          parameters:
            data.parameters && data.parameters.length > 0 ? data.parameters.filter((item: boolean | string) => item !== false) || [] : [],
        };
        await campaignsStore.createCampaign(createRestaurantData).then((response) => {
          history.push(`/campaigns/campaign/edit/${response.id}`);
          toast.success(`Campaigns '${data.name}' successfully created`);
          campaigns.reload();
        });
      }
      campaigns.reload();
    },
    [campaigns, campaignsStore, history]
  );

  const onArchive = async (data: any) => {
    const request = new UpdateCampaignRequest();
    request.isEnabled = false;
    await updateCampaign(data.id, request);
    toast(`Campaign '${data.name}' archived !`);
    campaigns.reload();
    if (campaigns === undefined || campaigns.data === undefined || campaigns.data?.data.length <= 1)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const onDelete = async (data: any) => {
    await deleteCampaign(data.id);
    toast(`Campaign '${data.name}' deleted !`);
    campaigns.reload();
    if (campaigns === undefined || campaigns.data === undefined || campaigns.data?.data.length <= 1)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const onSeeCampaigns = () => {
    localStorage.removeItem("campaigns");
    setCurrentPage(1);
    if (archivedCampaignsOnly) {
      history.push("/campaigns/active");
    } else {
      history.push("/campaigns/archived");
    }
  };

  const goToCampaignPage = (campaign: CampaignModelType) => () => {
    localStorage.setItem("campaigns", JSON.stringify(currentPage));
    return history.push(`/campaigns/campaign/${campaign.id}`);
  };

  return (
    <HeaderLayout title="Campaigns">
      <DataGridForm
        ref={formRef}
        title={(form) => {
          const data = form.getValues();
          return data.id ? `Edit a Campaign` : "Create a Campaign";
        }}
        onSubmit={onSubmitForm}
        okLabel={"Next"}
        valueInjectFilters={{
          condition: (v: Object) => JSON.stringify(v, undefined, 2),
        }}
      >
        {(form) => <CampaignForm form={form} parameters={parameters} />}
      </DataGridForm>
      <DataGridDelete
        ref={deleteRef}
        onDelete={onDelete}
        title="Delete a Campaign"
        description={(data) => `Are you sure you wish to delete campaign '${data?.name || ""}' ? This cannot be undone.`}
      />
      <DataGridArchive
        ref={archiveRef}
        onArchive={onArchive}
        title="Archive a Campaign"
        description={(data) => `Are you sure you wish to archive campaign '${data?.name || ""}' ?`}
      />
      <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        {!campaigns.data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
            <DataGridHeader
              searchPlaceholder="Find a Campaign by name, code..."
              search={search}
              onSearchChange={setSearch}
              actions={
                <>
                  <button
                    onClick={() => formRef.current!.open()}
                    type="button"
                    className="ml-2 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                  >
                    New Campaign
                  </button>
                  <button
                    onClick={() => {
                      onSeeCampaigns();
                    }}
                    type="button"
                    className={clsx(
                      `ml-2 inline-flex items-center
                      px-4 py-2.5 border border-transparent text-xs
                      font-medium rounded shadow-md
                      focus:outline-none text-white
                      hover:bg-primary-active
                      focus:ring-2 focus:ring-offset-1 focus:ring-primary-active`,
                      "bg-primary"
                    )}
                  >
                    See {!archivedCampaignsOnly ? "Archived" : "Active"} Campaigns
                  </button>
                </>
              }
            />
            {campaigns.data?.data.length === 0 ? (
              <Empty title="No Campaigns Found" />
            ) : (
              <div className="w-full overflow-x-auto rounded shadow-md">
                <DataGridTable dataProvider={campaigns} columns={columns}>
                  {(data) =>
                    data.data.map((campaign) => (
                      <tr key={campaign.id} onClick={goToCampaignPage(campaign)} className="cursor-pointer hover:bg-secondary">
                        <td className="px-6 py-3 text-sm text-info whitespace-nowrap">{campaign.name}</td>
                        <td className="flex justify-end px-6 py-3 space-x-3 text-sm font-medium text-left whitespace-nowrap">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              campaign.isEnabled ? archiveRef.current!.open(campaign) : onEnable(campaign);
                            }}
                            type="button"
                            title={campaign.isEnabled ? "Archive" : "UnArchive"}
                            className="text-primary hover:text-primary-active"
                          >
                            {campaign.isEnabled ? <FontAwesomeIcon icon={faArchive} /> : <FontAwesomeIcon icon={faUndo} />}
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteRef.current!.open(campaign);
                            }}
                            type="button"
                            title="Delete"
                            className="text-error hover:text-error-active"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </DataGridTable>
              </div>
            )}
            {campaigns.data && campaigns.data.data.length !== 0 && (
              <div className="flex items-center justify-between w-full mt-3 mb-12">
                <span className="hidden text-sm sm:block text-info">
                  <b>{campaigns.data.count}</b> results on <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                </span>
                <div>
                  <Pagination currentPage={currentPage} onPageChange={onCampaignPageChange} maxPages={pages} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
