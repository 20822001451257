import { IAnyType } from "mobx-state-tree";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-async";
import { PaginatedModels } from "src/app/models/paginated-models.model";
import { useDebounce } from "use-debounce";

export function useDataGrid<ENTITY extends IAnyType>(props: {
  findFunction: (params: any) => Promise<PaginatedModels<ENTITY>>;
  limit?: number;
  startPage?: number;
  params?: any;
}) {
  const { findFunction, limit = 10, startPage = 1, params = {}} = props;

  const [currentPage, setCurrentPage] = useState<number>(startPage);
  const [itemsPerPage, setItemsPerPage] = useState<number>(limit);
  const [search, setSearch] = useState<string>("");
  const [findEntities, { flush }] = useDebounce(
    useCallback(async () => {
      return findFunction({ skip: search === "" ? (currentPage - 1) * itemsPerPage : 0, limit: itemsPerPage, query: search, ...params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, findFunction, limit, itemsPerPage, currentPage, JSON.stringify(params)]),
    200
  );

  useEffect(() => {
    flush();
  }, [currentPage, itemsPerPage, flush]);

  const entities = useAsync({
    promiseFn: findEntities,
  });

  const pages = useMemo(() => {
    return entities.data ? Math.ceil(entities.data.count / itemsPerPage) : 0;
  }, [entities, itemsPerPage]);

  return {
    search,
    setSearch,
    entities,
    pages,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
  };
}
