import { Instance, types } from "mobx-state-tree";
import { DateTimeType } from "./date-time.custom";

export const VariationModel = types.model('VariationModel', {
    id: types.string,
    name: types.string,
    description: types.string,
    values: types.frozen(),
    weight: types.number,
    isDefault: types.boolean,
    campaignId: types.string,
    createdAt: DateTimeType,
    updatedAt: DateTimeType,
  })
  .actions((self) => ({}))
  .views((self) => ({}))
  .actions((self) => ({}));

export type VariationModelType = Instance<typeof VariationModel>;
