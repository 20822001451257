import { useCallback } from "react";
import { observer } from "mobx-react";
import { useAsync } from "react-async";
import { useHistory, useParams } from "react-router-dom";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useStore } from "src/app/contexts/store.context";
import { CampaignDetails } from "src/app/components/Campaigns/CampaignDetails";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";

interface CampaignViewParams {
  campaignId: string;
}

export const parametersType: any[] = [];

export const CampaignView = observer(() => {
  const history = useHistory();
  const { campaigns: campaignsStore } = useStore();
  const { getEntityAsync } = campaignsStore;

  const { campaignId } = useParams<CampaignViewParams>();

  const fetchCampaign = useCallback(async () => {
    return getEntityAsync(campaignId, true);
  }, [getEntityAsync, campaignId]);
  const { data } = useAsync({
    promiseFn: fetchCampaign,
  });

  if (!data) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen pt-16 pb-12 mt-48">
        <LoadingSpinner type="TailSpin" color="black" height="50" />
      </div>
    )
  }
  return (
    <HeaderLayout
      left={
        <button type="button" onClick={() => history.push(data.isEnabled ? '/campaigns/active' : '/campaigns/archived', {from : "campaigns"})}>
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          Back
        </button>
      }
      title="Campaigns"
    >
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
        {!data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="flex flex-col w-full px-4 py-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ">
            <div className="xl:grid xl:grid-cols-2">
              <div className="xl:col-span-2 xl:pr-4 xl:border-gray-200">
                <div className="p-4 bg-white rounded shadow-md">
                  <CampaignDetails campaign={data} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
