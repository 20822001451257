import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useAsync } from "react-async";
import { useHistory, useParams } from "react-router-dom";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useStore } from "src/app/contexts/store.context";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FieldValues, SubmitHandler, useForm, UseFormReturn } from "react-hook-form";
import { DashboardForm } from "src/app/components/Dashboards/DashboardForm";
import { UpdateDashboardRequest } from "@acrelec-cloud/recommendation-shared";
import toast from "react-hot-toast";
import { pick } from "lodash";
import { extractErrorMessage } from 'src/app/utils/error';


interface DashboardViewParams {
  dashboardId: string;
}

type FormProps<TFormValues extends FieldValues> = {
  onSubmit: SubmitHandler<TFormValues>;
  dashboard: any;
  children: (methods: UseFormReturn<FieldValues>) => React.ReactNode;
};

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({ onSubmit, children, dashboard }: FormProps<TFormValues>) => {
  const methods = useForm({
    defaultValues: dashboard || {},
  });

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <DashboardForm form={methods} />
      {children(methods)}
    </form>
  );
};

export const DashboardEdit = observer(() => {
  const history = useHistory();
  const { dashboards: dashboardsStore } = useStore();
  const { getEntityAsync: getDashboard } = dashboardsStore;

  const { dashboardId } = useParams<DashboardViewParams>();
  const parsedId = parseInt(dashboardId);

  const fetchDashboard = useCallback(async () => {
    return getDashboard(parsedId);
  }, [getDashboard, parsedId]);

  const { data } = useAsync({
    promiseFn: fetchDashboard,
  });

  const dashboard = useAsync({
    promiseFn: fetchDashboard,
  });

  if (!dashboard?.data) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen pt-16 pb-12 mt-48 bg-white">
        <LoadingSpinner type="TailSpin" color="black" height="50" />
      </div>
    );
  }
  const onSubmit = async (data: any) => {
    const updateDashboardData: UpdateDashboardRequest = {
      ...pick(data, ["name", "description", "theme", "isTitled", "isBordered"]),
    };

    try {

      const update = await dashboardsStore.updateDashboard(data.id, updateDashboardData);
      toast.success(`Dashboard '${update.name}' successfully updated`);
      history.push(`/dashboards`);
    } catch (error: any) {
      toast.error(`Error updating dashboard ${data.name} : ${extractErrorMessage(error)}`);
    }
  };

  return (
    <HeaderLayout
      left={
        <button type="button" onClick={() => history.goBack()}>
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          Back
        </button>
      }
      title="Dashboards"
    >
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
        {!data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="flex flex-col w-full px-4 py-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ">
            <div className="xl:grid xl:grid-cols-2">
              <div className="xl:col-span-2 xl:pr-4 xl:border-gray-200">
                <div className="p-4 bg-white rounded shadow-md">
                  <Form dashboard={dashboard.data} onSubmit={onSubmit}>
                    {() => (
                      <div className="flex-shrink-0 px-2 py-3 border-t border-divider sm:px-6">
                        <div className="flex justify-between space-x-3">
                          <button
                            type="button"
                            className="px-4 py-2 text-sm font-medium bg-white rounded-md text-info hover:bg-white-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            onClick={() => history.goBack()}
                          >
                            Cancel
                          </button>
                          <input
                            type="submit"
                            value="Save"
                            className="ml-2 inset-y-0 right-0 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                          />
                        </div>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
