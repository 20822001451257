import { FingerPrintIcon } from "@heroicons/react/outline";
import { FormEvent, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import Logo from "../../../../assets/images/acrelec.svg";

export const RequestPasswordView = () => {
  const GET_EMAIL_STEP = 0;
  const MAIL_SENDED_STEP = 1;

  // const {
  //   authentication: { requestResetPasswordToken },
  // } = useApi();

  const [userEmail, setUserEmail] = useState<string>("");
  const [submitError, setSubmitError] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const onFormSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (userEmail.length > 0) {
        setLoading(true);
        setStep(MAIL_SENDED_STEP);
      } else setSubmitError("Veuillez saisir une adresse e-mail");
    },
    [userEmail]
  );

  return (
    <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center md:items-end sm:block sm:p-0">
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>

      {step === GET_EMAIL_STEP /* Step n°1 (E-mail form) */ && (
        <form
          onSubmit={(e) => onFormSubmit(e)}
          className="inline-block text-left align-bottom transition-all transform rounded-lg sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div className="flex justify-center py-12">
            <img className="w-auto h-12" src={Logo} alt="Logo Acrelec" />
          </div>

          <div className="sm:flex sm:items-start">
            <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-opacity-10 bg-success sm:mx-0 sm:h-10 sm:w-10">
              <FingerPrintIcon className="w-6 h-6 text-success" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg font-medium leading-6 text-primary">Réinitialisez votre mot de passe</h3>
              <div className="mt-2">
                <p className="text-sm text-info">
                  Un lien de réinitialisation va vous être envoyé par e-mail pour la configuration de votre nouveau mot de passe.
                </p>
              </div>
              <p className="w-full h-3 py-3 text-xs text-center text-error">{submitError}</p>
              <div className="pb-1">
                <label htmlFor="email" className="block mt-3 text-sm font-medium text-info">
                  Adresse e-mail
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email@example.fr"
                    className="block w-full rounded-md shadow-sm border-divider focus:ring-primary focus:border-primary sm:text-sm"
                    onChange={(e) => setUserEmail(e.target.value)}
                    onFocus={() => setSubmitError("")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-3 pb-6 mt-4 lg:mt-0 sm:flex sm:flex-row-reverse">
            <button
              type="submit"
              className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border rounded-md shadow-sm bg-primary hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
            >
              {loading ? <LoadingSpinner type="TailSpin" color={"#e2e2e2"} height={"16px"} width={"16px"} /> : <span>Envoyer</span>}
            </button>
            <Link
              to="/login"
              className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border rounded-md shadow-sm border-divider text-info hover:bg-divider-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Annuler
            </Link>
          </div>
        </form>
      )}

      {step === MAIL_SENDED_STEP /* Step n°2 */ && (
        <div className="inline-block text-left align-bottom transition-all transform rounded-lg sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="flex justify-center w-full">
            <div className="p-2 mt-4 rounded-full bg-opacity-10 bg-success text-success">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          <span className="block mt-8 text-lg text-center text-primary">
            Un lien vient de vous être envoyé <span className="font-semibold">par e-mail</span>.
          </span>
          <div className="px-8 py-8 mx-auto mt-6 text-xs rounded-md text-primary bg-success bg-opacity-10 opacity-90">
            Pensez à vérifier le répertoire de votre boite e-mail dédié aux <b>courriers indésirables.</b>
          </div>
          <div className="py-6 pb-6 sm:flex sm:flex-row-reverse">
            <Link
              to="/login"
              className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border rounded-md shadow-sm border-divider text-primary hover:bg-divider-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Retour à la connexion
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
