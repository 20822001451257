import React, { ReactNode, useContext } from "react";
import { createContext } from "react";

import { rootStore, RootStoreType } from "../stores/root.store";

const StoreContext = createContext<RootStoreType | undefined>(undefined);

export interface StoreProviderProps {
  children: ReactNode
}

export function StoreProvider(props: StoreProviderProps) {
  const { children } = props;
  return (
    <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
  );
}

export const useStore = () => useContext(StoreContext) as RootStoreType;
