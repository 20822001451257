import { CreateStoreRequest, UpdateStoreRequest } from "@acrelec-cloud/recommendation-shared";
import { faSync, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pick, toInteger } from "lodash";
import { observer } from "mobx-react";
import { ElementRef, useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { DataGridDelete } from "src/app/components/DataGrid/DataGridDelete";
import { DataGridForm } from "src/app/components/DataGrid/DataGridForm";
import { DataGridHeader } from "src/app/components/DataGrid/DataGridHeader";
import { DataGridTable } from "src/app/components/DataGrid/DataGridTable";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { ImportRestaurantsForm } from "src/app/components/Restaurants/ImportRestaurantsForm";
import { RestaurantForm } from "src/app/components/Restaurants/RestaurantForm";
import { Empty } from "src/app/components/Shared/Empty/Empty";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { Pagination } from "src/app/components/Shared/Pagination/Pagination";
import { useStore } from "src/app/contexts/store.context";
import { useDataGrid } from "src/app/hooks/useDataGrid.hook";
import { useExportStores } from "src/app/hooks/useExportStores";
import { RestaurantModelType } from "src/app/models/restaurant.model";
import { extractErrorMessage } from "src/app/utils/error";

const columns = [
  {
    label: "ID",
    name: "id",
  },
  {
    label: "ATP Code",
    name: "atpStoreCode",
  },
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Latitude",
    name: "latitude",
  },
  {
    label: "Longitude",
    name: "longitude",
  },
  {
    label: "",
    name: "actions",
  },
];

function limitDigit(value: number): number {
  return Number(value.toFixed(6));
}

export const RestaurantsView = observer(() => {
  const { restaurants: restaurantsStore } = useStore();
  const {
    authentication: { isAtpUser },
  } = useStore();
  const history = useHistory();

  const getStartPage = () => {
    if (history.location.state && typeof history.location.state === "object") {
      const from: any = history.location.state;
      if (from.from === "RestaurantView" && localStorage.getItem("restaurantsPage")) {
        return toInteger(localStorage.getItem("restaurantsPage"));
      }
    }
    return 1;
  };

  const {
    search,
    setSearch,
    entities: restaurants,
    pages,
    currentPage,
    setCurrentPage,
  } = useDataGrid({
    limit: 20,
    startPage: getStartPage(),
    findFunction: restaurantsStore.findRestaurants,
  });

  const formRef = useRef<ElementRef<typeof DataGridForm>>(null);
  const importformRef = useRef<ElementRef<typeof DataGridForm>>(null);
  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);

  const [syncingRestaurant, setSyncingRestaurant] = useState<number>();

  useEffect(() => {
    localStorage.removeItem("restaurantsPage");
  }, [history]);

  const onStorePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const onSubmitForm = useCallback(
    async (data: any) => {
      if (!!data.id) {
        const updateRestaurantData: UpdateStoreRequest = {
          name: data.name,
          atpStoreCode: data.atpStoreCode ? data.atpStoreCode : null,
          location: { latitude: limitDigit(+data.location.latitude), longitude: limitDigit(+data.location.longitude) },
        };
        await restaurantsStore
          .updateRestaurant(data.id, updateRestaurantData)
          .then(() => toast.success(`Successfully updated store '${data.name}' `))
          .catch((e) => toast.error(`Error updating store '${data.name}' : ${extractErrorMessage(e)}`));
      } else {
        const createRestaurantData: CreateStoreRequest = {
          ...pick(data, ["name"]),
          atpStoreCode: data.atpStoreCode ? data.atpStoreCode : null,
          location: { latitude: limitDigit(+data.location.latitude), longitude: limitDigit(+data.location.longitude) },
        };
        await restaurantsStore
          .createRestaurant(createRestaurantData)
          .then(() => toast.success(`Successfully created Store '${data.name}' `))
          .catch((e) => toast.error(`Error creating Store '${data.name}' : ${extractErrorMessage(e)}`));
      }

      restaurants.reload();
    },
    [restaurantsStore, restaurants]
  );

  const onSubmitImportForm = useCallback(
    async (data: any) => {
      if (!data.stores) {
        toast("No stores were imported");
        return;
      }
      const importStores = Promise.allSettled(
        data.stores.map(async (store: any) => {
          const { name, atpStoreCode, location } = store.value;
          await restaurantsStore
            .createRestaurant({
              name,
              atpStoreCode,
              location: {
                latitude: limitDigit(+location.latitude),
                longitude: limitDigit(+location.longitude),
              },
            })
            .catch((e) => toast.error(`Error creating Store '${name}' [ID:${atpStoreCode}]: ${extractErrorMessage(e)}`))
            .then(() => toast.success(`Successfully imported Stores '${name}' [ID:${atpStoreCode}]`));
        })
      );
      toast
        .promise(importStores, {
          loading: "Importing stores...",
          success: "Import successful",
          error: (e) => `Import failed: ${e.message}}`,
        })
        .finally(() => restaurants.reload());
    },
    [restaurantsStore, restaurants]
  );

  const syncRestaurant = useCallback(
    async (restaurant: RestaurantModelType) => {
      if (restaurant.atpStoreCode) {
        setSyncingRestaurant(restaurant.id);
        await restaurantsStore
          .findAtpRestaurants({ query: `${restaurant.atpStoreCode}` })
          .then(async (atpRestaurants) => {
            if (atpRestaurants[0]) {
              await restaurantsStore
                .updateRestaurant(restaurant.id, {
                  name: atpRestaurants[0].name,
                  location: {
                    latitude: limitDigit(+atpRestaurants[0].location.latitude),
                    longitude: limitDigit(+atpRestaurants[0].location.longitude),
                  },
                })
                .then(() => {
                  setSyncingRestaurant(undefined);
                  toast.success(`${restaurant.name} successfully synchronized with ATP`);
                })
                .catch(() => {
                  setSyncingRestaurant(undefined);
                  toast.error(`${restaurant.name} failed to synchronize with ATP`);
                });
              restaurants.reload();
            } else {
              setSyncingRestaurant(undefined);
              toast.success(`${restaurant.name} successfully synchronized with ATP`);
            }
          })
          .catch(() => {
            setSyncingRestaurant(undefined);
            toast.error(`${restaurant.name} failed to synchronize with ATP`);
          });
      }
    },
    [restaurantsStore, restaurants]
  );

  const onDelete = useCallback(
    async (data: any) => {
      await restaurantsStore
        .deleteRestaurant(data.id)
        .then(() => toast.success(`Successfully deleted Store '${data.name}' `))
        .catch((e) => toast.error(`Error deleting Store '${data.name}' : ${extractErrorMessage(e)}`));
      restaurants.reload();
      if (restaurants === undefined || restaurants.data === undefined || restaurants.data?.data.length <= 1)
        setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
    },
    [restaurantsStore, restaurants, setCurrentPage, currentPage]
  );

  const onExportStore = useExportStores();

  return (
    <HeaderLayout title="Stores">
      <DataGridForm
        ref={formRef}
        title={(form) => {
          const data = form.getValues();
          return data.id ? `Edit a Store` : "Create a Store";
        }}
        onSubmit={onSubmitForm}
      >
        {(form) => <RestaurantForm form={form}></RestaurantForm>}
      </DataGridForm>
      <DataGridForm ref={importformRef} title="Import Stores" okLabel="Import" onSubmit={onSubmitImportForm}>
        {(form) => <ImportRestaurantsForm form={form}></ImportRestaurantsForm>}
      </DataGridForm>
      <DataGridDelete
        ref={deleteRef}
        onDelete={onDelete}
        title="Delete a Store"
        description={(data) => `Are you sure you wish to delete store '${data?.name || ""}' ? This cannot be undone.`}
      />
      <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        {!restaurants.data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
            <DataGridHeader
              searchPlaceholder="Find a Store by name, code..."
              search={search}
              onSearchChange={setSearch}
              actions={
                <>
                  {isAtpUser && (
                    <button
                      onClick={() => importformRef.current!.open()}
                      type="button"
                      className="ml-2 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                    >
                      Import Stores
                    </button>
                  )}
                  <button
                    onClick={() => onExportStore()}
                    type="button"
                    className="ml-2 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                  >
                    Export Stores
                  </button>
                  <button
                    onClick={() => formRef.current!.open()}
                    type="button"
                    className="ml-2 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                  >
                    New Store
                  </button>
                </>
              }
            />
            {restaurants.data?.data.length === 0 ? (
              <Empty title="No Stores Found" />
            ) : (
              <div className="w-full overflow-x-auto rounded shadow-md">
                <DataGridTable dataProvider={restaurants} columns={columns}>
                  {(data) =>
                    data.data.map((restaurant) => (
                      <tr
                        key={restaurant.id}
                        onClick={() => {
                          localStorage.setItem("restaurantsPage", JSON.stringify(currentPage));
                          return history.push(`/stores/${restaurant.id}`);
                        }}
                        className="cursor-pointer hover:bg-secondary"
                      >
                        <td className="px-4 py-3 text-sm font-medium text-info whitespace-nowrap">{restaurant.id}</td>
                        <td className="px-4 py-3 text-sm text-info whitespace-nowrap">{restaurant.atpStoreCode || "N/A"}</td>
                        <td className="px-4 py-3 text-sm text-info whitespace-nowrap">{restaurant.name}</td>
                        <td className="px-4 py-3 text-sm text-info whitespace-nowrap">{restaurant.location.latitude}</td>
                        <td className="px-4 py-3 text-sm text-info whitespace-nowrap">{restaurant.location.longitude}</td>
                        <td className="flex justify-end px-6 py-3 space-x-2 text-sm font-medium text-left whitespace-nowrap">
                          {isAtpUser && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                syncRestaurant(restaurant);
                              }}
                              type="button"
                              disabled={!restaurant.atpStoreCode}
                              title={!!restaurant.atpStoreCode ? "Sync with ATP" : "An ATP Code is required to sync"}
                              className={
                                !!restaurant.atpStoreCode ? "text-primary hover:text-primary-active" : "text-gray-300 cursor-default"
                              }
                            >
                              <FontAwesomeIcon icon={faSync} spin={syncingRestaurant === restaurant.id} />
                            </button>
                          )}
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteRef.current!.open(restaurant);
                            }}
                            type="button"
                            title="Delete"
                            className="text-error hover:text-error-active"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </DataGridTable>
              </div>
            )}
            {restaurants.data && restaurants.data.data.length !== 0 && (
              <div className="flex items-center justify-between w-full mt-3 mb-12">
                <span className="hidden text-sm sm:block text-info">
                  <b>{restaurants.data.count}</b> results on <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                </span>
                <div>
                  <Pagination currentPage={currentPage} onPageChange={onStorePageChange} maxPages={pages} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
