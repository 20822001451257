import { useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useStore } from "src/app/contexts/store.context";
import { useDataGrid } from "src/app/hooks/useDataGrid.hook";
import { booleanType } from "../Parameters/ParametersFormOptions";

interface VariationParamtersProps {
  form?: UseFormReturn;
  parameters: any;
  variationParameter: any;
  isDisabled?: boolean;
}

interface FormInput {
  name: string;
  type: string;
  step?: string;
  defaultValue?: string;
  placeholder?: string;
  options?: { value: string; label: string }[];
  validationPattern?: string;
  size?: number;
  label: (data: any) => string;
  showIf?: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
}

export const VariationParamtersFrom = (props: VariationParamtersProps) => {
  const {
    form,
    parameters,
    isDisabled = false,
    variationParameter = undefined,
  } = props;


  const [engineType, setEngineType] = useState<{ label: string; value: any }[]>(
    []
  );
  const { engines: enginesStore } = useStore();
  const { entities: engines } = useDataGrid({
    findFunction: enginesStore.findEngines,
    limit: 1000
  });

  useEffect(() => {
    const newEngine: { label: string; value: any }[] = [];
    engines.data?.data.forEach((engine) => {
      newEngine.push({
        value: engine.name,
        label: engine.name,
      });
    });
    setEngineType(newEngine);
  }, [engines.data?.data]);

  const parameterInputs: FormInput[] = useMemo(() => {
    let filteredEngineValues: any[] = [];
    
    parameters.forEach((parameter: any) => {
      if (parameter.type === "ENGINE") {
        filteredEngineValues.push({
          label: parameter.value,
          value: parameter.value,
        });
      }
    });
    if (engineType) {
      engineType.forEach((engine) => {
        let i = filteredEngineValues.findIndex((x) => x.value === engine.value);
        if (i <= -1) {
          filteredEngineValues.push({
            label: engine.value,
            value: engine.value,
          });
        }
      });
    }

    return [
      {
        name: "values",
        type: "text",
        placeholder: "NULL",
        defaultValue: "NULL",
        label: () => "Value",
        requiredIf: () => true,
        showIf: (data) =>
          data.type === "STRING" ||
          data.type === undefined,
      },
      {
        name: "values",
        type: "text",
        placeholder: "NULL",
        defaultValue: "NULL",
        validationPattern: "[+-]?([0-9]*[.])?[0-9]+",
        label: () => "Value",
        requiredIf: () => true,
        showIf: (data) =>
          data.type === "NUMBER" 
      },
      {
        name: "values",
        type: "select",
        options: booleanType,
        label: () => "Value",
        requiredIf: () => true,
        showIf: (data) => data.type === "BOOLEAN",
      },
      {
        name: "values",
        type: "select",
        options: filteredEngineValues,
        label: () => "Value",
        requiredIf: () => true,
        showIf: (data) => data.type === "ENGINE",
      },
      {
        name: "values",
        type: "select-type-select",
        label: () => "Value select",
        requiredIf: () => true,
        showIf: (data) => data.type === "SELECT",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters, engineType]);

  useEffect(() => {
    if (form && form.getValues() && parameters) {
      const currentValue = form.getValues();
      parameters.forEach((value: { value: any; id: string | number }) => {
        value.value = currentValue.values[value.id];
      });
    }
  }, [form, isDisabled, parameters, variationParameter]);

  return (
    <>
      {parameters &&
        !isDisabled &&
        parameters.map((parameter: any) => (
          <div key={parameter.id}>
            <div>
              {parameterInputs.map((input) =>
                !input.showIf || input.showIf(parameter) ? (
                  <div
                    key={input.name}
                    className="grid grid-cols-3 gap-4 px-6 py-2 mb-3"
                  >
                    <div>
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >{parameter.name}</label>
                    </div>
                    <div className="col-span-2">
                    {input.type === "text" && (
                            <input
                            {...form?.register(input.name + "." + parameter.id + ".")}
                            type={input.type}
                            defaultValue={parameter.value ? parameter.value : ""}
                            size={input.size ? input.size : 255}
                            pattern={input.validationPattern ? input.validationPattern : ".+"}
                            required={input.requiredIf && input.requiredIf(parameter)}
                            className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                          />
                      )}
                      {input.type === "select" && (
                        <select
                          {...form?.register(
                            input.name + "." + parameter.id + "."
                          )}
                          required={
                            input.requiredIf && input.requiredIf(parameter)
                          }
                          className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                        >
                          {input.options?.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      )}
                      {input.type === "select-type-select" && (
                        <select
                          {...form?.register(
                            input.name + "." + parameter.id + "."
                          )}
                          required={
                            input.requiredIf && input.requiredIf(parameter)
                          }
                          className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                        >
                          {parameter.config.options?.map(
                            (option: any, index: any) => (
                              <option key={index} value={option.value}>
                                {option.value}
                              </option>
                            )
                          )}
                        </select>
                      )}
                      {input.type === "select-type-engine" && (
                        <select
                          {...form?.register(
                            input.name + "." + parameter.id + "."
                          )}
                          disabled
                          required={
                            input.requiredIf && input.requiredIf(parameter)
                          }
                          className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                        >
                          {engineType?.map((option: any, index: any) => (
                            <option key={index} value={option.value}>
                              {option.value}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </div>
        ))}
    </>
  );
};
