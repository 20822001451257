import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ReactNode } from "react";

interface DataGridHeaderProps {
  searchPlaceholder: string;
  search: string;
  onSearchChange: (search: string) => void;
  actions?: ReactNode;
}

export const DataGridHeader = (props: DataGridHeaderProps) => {
  const { searchPlaceholder, search, onSearchChange, actions } = props;

  return (
    <div className="flex flex-wrap w-full py-3">
      <div className="w-full sm:flex-1 sm:w-auto">
        <div className={clsx("relative flex-1 text-gray-400")}>
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <FontAwesomeIcon className="text-primary" icon={faSearch} />
          </div>
          <input
            id="search-field"
            className="block w-full h-full py-2 pl-8 pr-3 text-black bg-transparent border-none placeholder-info focus:outline-none focus:placeholder-info focus:ring-0 focus:border-none sm:text-sm"
            placeholder={searchPlaceholder}
            type="text"
            autoComplete="off"
            name="search"
            value={search}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </div>
      </div>
      {actions}
    </div>
  );
};
