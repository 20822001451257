import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useEffect, useMemo, useRef } from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { useStore } from "src/app/contexts/store.context";
import { useDataGrid } from "src/app/hooks/useDataGrid.hook";
import { OptionsAccordion } from "./OptionsAccordion";
import { booleanType, engineType, optionsType } from "./ParametersFormOptions";

interface ParameterFormProps {
  form: UseFormReturn;
  configArray: any[];
  setConfigArray: (array: any[]) => void;
  selectDefaultValue: any[];
  setSelectDefaultValue: (array: any[]) => void;
  parameter?: any;
  modalRef: any;
}

interface FormInput {
  name: string;
  type: string;
  step?: string;
  defaultValue?: any;
  checkedLabel?: string;
  uncheckedLabel?: string;
  checkedValue?: string | boolean;
  uncheckedValue?: string | boolean;
  placeholder?: string;
  options?: { value: any; label: string }[];
  validationPattern?: string;
  label: (data: any) => string;
  showIf?: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
}

export const ParameterForm = observer((props: ParameterFormProps) => {
  const { form, configArray, setConfigArray, selectDefaultValue, setSelectDefaultValue, modalRef } = props;
  const data = useWatch(form);

  useEffect(() => {
    if (data.id === undefined) {
      form.setValue("type", "STRING");
      form.setValue("name", "");
      form.setValue("description", "");
      form.setValue("isEnabled", true);
      form.reset(form.getValues());
    }
  }, [data.id, form]);

  const { engines: enginesStore } = useStore();
  const { entities: engines } = useDataGrid({
    findFunction: enginesStore.findEngines,
    limit: 1000,
  });

  const parameterInputs: FormInput[] = [
    {
      name: "name",
      type: "text",
      placeholder: "Engine",
      defaultValue: "",
      label: () => "Name",
      requiredIf: () => true,
    },
    {
      name: "description",
      type: "text",
      placeholder: "Text",
      defaultValue: "",
      label: () => "Description",
      requiredIf: () => true,
    },
    {
      name: "isEnabled",
      type: "switch",
      defaultValue: true,
      uncheckedValue: false,
      checkedValue: true,
      uncheckedLabel: "No",
      checkedLabel: "Yes",
      label: () => "Active",
      requiredIf: () => true,
    },
    {
      name: "type",
      type: "select",
      defaultValue: "STRING",
      options: optionsType,
      label: () => "Type",
      requiredIf: () => true,
    },
    {
      name: "defaultValue",
      type: "text",
      placeholder: "NULL",
      defaultValue: "NULL",
      label: () => "Default Value",
      requiredIf: () => true,
      showIf: (data) => data.type === "STRING" || data.type === undefined,
    },
    {
      name: "defaultValue",
      type: "text",
      placeholder: "NULL",
      defaultValue: -1,
      validationPattern: "[+-]?([0-9]*[.])?[0-9]+",
      label: () => "Default Value",
      requiredIf: () => true,
      showIf: (data) => data.type === "NUMBER",
    },
    {
      name: "defaultValue",
      type: "select",
      defaultValue: true,
      options: booleanType,
      label: () => "Default Value",
      requiredIf: () => true,
      showIf: (data) => data.type === "BOOLEAN",
    },
    {
      name: "defaultValue",
      type: "select",
      defaultValue: useMemo(() => {
        return engineType[0]?.value || null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [engineType.length]),
      options: engineType,
      label: () => "Default Value",
      requiredIf: () => true,
      showIf: (data) => data.type === "ENGINE",
    },
    {
      name: "defaultValue",
      type: "select",
      options: useMemo(() => {
        let filteredOptionValues: any[] = [];
        if (data.config && data.config.options && data.config.options.length > 0) {
          data.config.options.forEach((option: any) => {
            filteredOptionValues.push(option);
          });
        } 
        return filteredOptionValues;
      }, [data]),
      defaultValue: useMemo(() => {
        let filteredOptionValues: any[] = [];
        if (data.config && data.config.options && data.config.options.length > 0) {
          data.config.options.forEach((option: any) => {
            filteredOptionValues.push(option);
          });
        }
        return filteredOptionValues[0]?.value || null;
      }, [data]),
      label: () => "Default Value",
      requiredIf: () => true,
      showIf: (data) => data.type === "SELECT" && data.config && data.config.options && data.config.options.length > 0,
    },
    {
      name: "option",
      type: "options",
      label: () => "Option",
      requiredIf: () => true,
      showIf: (data) => data.type === "SELECT",
    },
  ];

  const oldType = useRef(data.type);
  useEffect(() => {
    if (data.type !== undefined && oldType.current !== data.type) {
      oldType.current = data.type;
      const inputType = parameterInputs.find(
        (parameterInput) => parameterInput.name === "defaultValue" && parameterInput.showIf && parameterInput.showIf(data)
      );
      if (inputType) {
        form.setValue("defaultValue", inputType.defaultValue);
      } else {
        form.setValue("defaultValue", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.type, form]);
  const executeScroll = () => modalRef.current.scrollIntoView({ behavior: "smooth" });
  const onAddOption = () => {
    if (data.config === undefined || data.config.options === undefined) {
      data.config = { options: [] };
    }

    const addOptionArray = [{ label: "", value: "" }];
    const addDefaultValue = [{ label: "", value: "" }];
    if (data.config.options.length === 0) {
      form.setValue("defaultValue", "");
    }

    const newOptionArray = data.config.options.concat(addOptionArray);
    const newDefaultValueArray = selectDefaultValue.concat(addDefaultValue);
    form.setValue("config.options", newOptionArray);

    setConfigArray(newOptionArray);
    setSelectDefaultValue(newDefaultValueArray);
  };

  const onDeleteOption = (optionIndex: number) => {
    setConfigArray([]);
    setSelectDefaultValue([]);
    const deletedOptionArray = data.config.options.filter((option: any, index: any) => index !== optionIndex);
    const deletedDefaultValue = selectDefaultValue.filter((option: any, index: any) => index !== optionIndex + 1);
    form.setValue("config.options", deletedOptionArray);

    if (deletedOptionArray && deletedOptionArray.length > 0) {
      if (deletedOptionArray.length > 0) {
        form.setValue("defaultValue", deletedOptionArray[0]?.value);
      }
      else {
        form.setValue("defaultValue", undefined);
      }
    }
    setConfigArray(deletedOptionArray);
    setSelectDefaultValue(deletedDefaultValue);
    //form.reset();
  };

  useEffect(() => {
    engines.data?.data.forEach((engine) => {
      const existingEngine = engineType.find((engineOption) => engineOption.value === engine.name);
      if (!existingEngine) {
        engineType.push({
          value: engine.name,
          label: engine.name,
        });
      }
    });
  }, [engines.data?.data]);

  return (
    <>
      {data.id && (
        <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
              ID
            </label>
          </div>
          <div className="col-span-2">
            <input
              type="text"
              defaultValue={data.id}
              disabled
              className="block w-full text-gray-500 rounded-md border-divider sm:text-sm"
            />
          </div>
        </div>
      )}
      {parameterInputs.map((input) =>
        !input.showIf || input.showIf(data) ? (
          <div key={input.name} className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {input.label(data)}
              </label>
            </div>
            <div className="col-span-2">
              {input.type === "text" && (
                <input
                  {...form.register(input.name)}
                  type={input.type}
                  defaultValue=""
                  step={input.step}
                  pattern={input.validationPattern ? input.validationPattern : ".+"}
                  placeholder={input.placeholder}
                  required={input.requiredIf && input.requiredIf(data)}
                  className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                />
              )}
              {input.type === "switch" && (
                <div className="flex items-center w-full">
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <div>{input.uncheckedLabel}</div>
                    <div className="relative">
                      {!form && (
                        <>
                          <input type="checkbox" className="sr-only" />
                          <div
                            className={clsx(
                              "block h-8 rounded-full w-14",
                              data[input.name] === input.checkedValue ? "bg-primary" : "bg-gray-400"
                            )}
                          ></div>
                          <div
                            className={clsx(
                              "absolute w-6 h-6 transition bg-white rounded-full left-1 top-1",
                              data[input.name] === input.checkedValue ? "transform translate-x-full" : ""
                            )}
                          ></div>
                        </>
                      )}
                      {form && (
                        <Controller
                          name={input.name}
                          defaultValue={input.uncheckedValue}
                          control={form?.control}
                          render={({ field }) => (
                            <>
                              <input
                                type="checkbox"
                                onChange={(event) => {
                                  field.onChange(event.target.checked ? input.checkedValue : input.uncheckedValue);
                                }}
                                className="sr-only"
                              />
                              <div
                                className={clsx(
                                  "block h-8 rounded-full w-14",
                                  data[input.name] === input.checkedValue ? "bg-primary" : "bg-gray-400"
                                )}
                              ></div>
                              <div
                                className={clsx(
                                  "absolute w-6 h-6 transition bg-white rounded-full left-1 top-1",
                                  data[input.name] === input.checkedValue ? "transform translate-x-full" : ""
                                )}
                              ></div>
                            </>
                          )}
                        />
                      )}
                    </div>
                    <div>{input.checkedLabel}</div>
                  </label>
                </div>
              )}
              {input.type === "select" && (
                <select
                  {...form.register(input.name)}
                  required={input.requiredIf && input.requiredIf(data)}
                  className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                >
                  {input.options?.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
              {input.type === "options" && (
                <div>
                  {data?.config?.options?.map((array: any, index: any) => (
                    <div key={index} className="flex flex-row justify-between">
                      <OptionsAccordion
                        form={form}
                        configArray={configArray}
                        selectDefaultValue={selectDefaultValue}
                        optionIndex={index}
                        setSelectDefaultValue={setSelectDefaultValue}
                      />
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteOption(index);
                        }}
                        type="button"
                        title="Delete"
                      >
                        <div className="ml-3">
                          <FontAwesomeIcon className="w-5 h-5" icon={faTrashAlt} />
                        </div>
                      </button>
                    </div>
                  ))}
                  <div ref={modalRef}>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onAddOption();
                        executeScroll();
                      }}
                      type="button"
                      className="py-2 text-sm font-medium text-left text-black underline decoration-solid decoration-black decoration-2"
                    >
                      Add option
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null
      )}
    </>
  );
});
