import React from "react";
import { observer } from "mobx-react";

export const AdminFooter = observer(() => {

  return (
    <nav className="z-30 h-16 bg-header">
      <div className="flex items-center justify-center h-full text-sm font-bold text-white">
        <p>© 2021 - {new Date().getFullYear()} Acrelec, V {process.env.REACT_APP_VERSION}</p>
      </div>
    </nav>
  );
});
