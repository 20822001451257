import { CreateParameterRequest, UpdateParameterRequest } from "@acrelec-cloud/recommendation-shared";
import { faArchive, faTrashAlt, faUndo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { pick, toInteger } from "lodash";
import { observer } from "mobx-react";
import { ElementRef, useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import { DataGridArchive } from "src/app/components/DataGrid/DataGridArchive";
import { DataGridDelete } from "src/app/components/DataGrid/DataGridDelete";
import { DataGridForm } from "src/app/components/DataGrid/DataGridForm";
import { DataGridHeader } from "src/app/components/DataGrid/DataGridHeader";
import { DataGridTable } from "src/app/components/DataGrid/DataGridTable";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { ParameterForm } from "src/app/components/Parameters/ParameterForm";
import { Empty } from "src/app/components/Shared/Empty/Empty";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { Pagination } from "src/app/components/Shared/Pagination/Pagination";
import { useStore } from "src/app/contexts/store.context";
import { useDataGrid } from "src/app/hooks/useDataGrid.hook";
import { ParameterModelType } from "src/app/models/parameter.model";
import { extractErrorMessage } from 'src/app/utils/error';

const columns = [
  {
    label: "Parameter Name",
    name: "name",
  },
  {
    label: "Type",
    name: "type",
  },
  {
    label: "",
    name: "actions",
  },
];

const optionArray = [{ name: "null", value: "NULL" }];

const defaultValueARray = [
  { value: "NULL", label: "NULL" },
  { value: "NULL", label: "NULL" },
];

interface ParametersViewParams {
  mode: string;
}

export const ParametersView = observer(() => {
  const { parameters: parametersStore } = useStore();
  const { updateParameter } = parametersStore;
  const { mode } = useParams<ParametersViewParams>();
  const history = useHistory();
  const archivedParametersOnly = mode === "archived";
  const modalRef = useRef<HTMLDivElement>(null);

  const getStartPage = () => {
    if (history.location.state && typeof history.location.state === "object") {
      const from: any = history.location.state;
      if (from.from === "parameters" && localStorage.getItem("parameters")) {
        return toInteger(localStorage.getItem("parameters"));
      }
    }
    return 1;
  };

  const {
    search,
    setSearch,
    entities: parameters,
    pages,
    currentPage,
    setCurrentPage,
  } = useDataGrid({
    findFunction: parametersStore.findParameters,
    startPage: getStartPage(),
    params: {
      isEnabled: !archivedParametersOnly,
    },
  });
  const [configArray, setConfigArray] = useState<any>(optionArray);
  const [selectDefaultValue, setSelectDefaultValue] = useState<any>(defaultValueARray);
  const formRef = useRef<ElementRef<typeof DataGridForm>>(null);
  const deleteRef = useRef<ElementRef<typeof DataGridDelete>>(null);
  const archiveRef = useRef<ElementRef<typeof DataGridArchive>>(null);

  const onParameterPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    localStorage.removeItem("parameters");
  }, [history]);

  const onSeeParameters = () => {
    localStorage.removeItem("parameters");
    setCurrentPage(1);
    if (archivedParametersOnly) {
      history.push("/parameters/active");
    } else {
      history.push("/parameters/archived");
    }
  };

  const onArchive = async (data: any) => {
    const request = new UpdateParameterRequest();
    request.isEnabled = false;
    await updateParameter(data.id, request);
    toast(`Parameter '${data.name}' archived !`);
    parameters.reload();
    if (parameters === undefined || parameters.data === undefined || parameters.data?.data.length <= 1)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const onEnable = async (data: any) => {
    const request = new UpdateParameterRequest();
    request.isEnabled = true;
    await updateParameter(data.id, request);
    toast(`Parameter '${data.name}' activated !`);
    parameters.reload();
    if (parameters === undefined || parameters.data === undefined || parameters.data?.data.length <= 1)
      setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const goToParametersPage = (parameter: ParameterModelType) => () => {
    localStorage.setItem("parameters", JSON.stringify(currentPage));
    return history.push(`/parameters/parameter/${parameter.id}`);
  };

  const onSubmitForm = useCallback(
    async (data: any) => {
      if (data.type === "SELECT" && !data.defaultValue) {
        data.close = false;
        throw new Error("This field is required : next to the 'Add Option'");
      }
      if (!!data.id) {
        const updateParameterData: UpdateParameterRequest = {
          name: data.name,
          type: data.type,
          description: data.description,
          config: data.config,
          defaultValue: data.defaultValue === "" ? null : data.defaultValue,
          isEnabled: data.isEnabled,
        };
        await parametersStore
          .updateParameter(data.id, updateParameterData)
          .then(() => toast.success(`Successfully updated parameter '${data.name}' `))
          .catch((e) => toast.error(`Error updating parameter '${data.name}' : ${extractErrorMessage(e)}`));
        setConfigArray(optionArray);
        setSelectDefaultValue(defaultValueARray);
      } else {
        const createParameterData: CreateParameterRequest = {
          ...pick(data, ["name"]),
          type: data.type,
          description: data.description,
          config: data.config === undefined ? {} : data.config,
          defaultValue: data.defaultValue === "" ? null : data.defaultValue,
          isEnabled: data.isEnabled,
        };
        await parametersStore
          .createParameter(createParameterData)
          .then(() => toast.success(`Successfully created parameter '${data.name}'`))
          .catch((e) => toast.error(`Error creating parameter '${data.name}' : ${extractErrorMessage(e)}`));
        setConfigArray(optionArray);
        setSelectDefaultValue(defaultValueARray);
      }
      parameters.reload();
    },
    [parameters, parametersStore]
  );

  const onDelete = useCallback(
    async (data: any) => {
      await parametersStore
        .deleteParameter(data.id)
        .then(() => toast.success(`Successfully deleted parameter '${data.name}'`))
        .catch((e) => toast.error(`Error deleting parameter '${data.name}' : ${extractErrorMessage(e)}`));
      parameters.reload();
      if (parameters === undefined || parameters.data === undefined || parameters.data?.data.length <= 1)
        setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
    },
    [parametersStore, parameters, setCurrentPage, currentPage]
  );

  return (
    <HeaderLayout title="Parameters">
      <DataGridForm
        ref={formRef}
        title={(form) => {
          const data = form.getValues();
          return data.id ? `Edit a Parameter` : "Create a Parameter";
        }}
        onSubmit={onSubmitForm}
      >
        {(form: any) => (
          <ParameterForm
            form={form}
            configArray={configArray}
            setConfigArray={setConfigArray}
            selectDefaultValue={selectDefaultValue}
            setSelectDefaultValue={setSelectDefaultValue}
            modalRef={modalRef}
          />
        )}
      </DataGridForm>
      <DataGridDelete
        ref={deleteRef}
        onDelete={onDelete}
        title="Delete a Parameter"
        description={(data) => `Are you sure you wish to delete Parameter '${data?.name || ""}' ? This cannot be undone.`}
      />
      <DataGridArchive
        ref={archiveRef}
        onArchive={onArchive}
        title="Archive a Parameter"
        description={(data) => `Are you sure you wish to archive parameter '${data?.name || ""}' ?`}
      />
      <div className="flex flex-col w-full px-1 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        {!parameters ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="relative flex flex-col flex-1 px-2 overflow-x-hidden">
            <DataGridHeader
              searchPlaceholder="Find a Parameter by name, description..."
              search={search}
              onSearchChange={setSearch}
              actions={
                <>
                  <button
                    onClick={() => {
                      setConfigArray(optionArray);
                      setSelectDefaultValue(defaultValueARray);
                      formRef.current!.open();
                    }}
                    type="button"
                    className="ml-2 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                  >
                    New Parameter
                  </button>
                  <button
                    onClick={() => {
                      onSeeParameters();
                    }}
                    type="button"
                    className={clsx(
                      `ml-2 inline-flex items-center
                      px-4 py-2.5 border border-transparent text-xs
                      font-medium rounded shadow-md
                      focus:outline-none text-white
                      hover:bg-primary-active
                      focus:ring-2 focus:ring-offset-1 focus:ring-primary-active`,
                      "bg-primary"
                    )}
                  >
                    See {!archivedParametersOnly ? "Archived" : "Active"} Parameters
                  </button>
                </>
              }
            />
            {parameters.data?.data.length === 0 ? (
              <Empty title="No parameters Found" />
            ) : (
              <div className="w-full overflow-x-auto rounded shadow-md">
                <DataGridTable dataProvider={parameters} columns={columns}>
                  {(data) =>
                    data.data.map((parameter) => (
                      <tr key={parameter.id} onClick={goToParametersPage(parameter)} className="cursor-pointer hover:bg-secondary">
                        <td className="px-6 py-3 text-sm text-info whitespace-nowrap">{parameter.name}</td>
                        <td className="px-4 py-3 text-sm text-info whitespace-nowrap">{parameter.type}</td>
                        <td className="flex justify-end px-6 py-3 space-x-3 text-sm font-medium text-left whitespace-nowrap">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              parameter.isEnabled ? archiveRef.current!.open(parameter) : onEnable(parameter);
                            }}
                            type="button"
                            title={parameter.isEnabled ? "Archive" : "UnArchive"}
                            className="text-primary hover:text-primary-active"
                          >
                            {parameter.isEnabled ? <FontAwesomeIcon icon={faArchive} /> : <FontAwesomeIcon icon={faUndo} />}
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteRef.current!.open(parameter);
                            }}
                            type="button"
                            title="Delete"
                            className="text-error hover:text-error-active"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </DataGridTable>
              </div>
            )}
            {parameters.data && parameters.data.data.length !== 0 && (
              <div className="flex items-center justify-between w-full mt-3 mb-12">
                <span className="hidden text-sm sm:block text-info">
                  <b>{parameters.data.count}</b> results on <b>{pages}</b> {pages === 1 ? "page" : "pages"}
                </span>
                <div>
                  <Pagination currentPage={currentPage} onPageChange={onParameterPageChange} maxPages={pages} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
