import classnames from "clsx";
import { observer } from "mobx-react";
import React, { useCallback, useRef, useState } from "react";
import { useStore } from "../../contexts/store.context";
import { useOnClickOutside } from "../../hooks/on-click-outside.hook";

export const UserDropdown = observer(() => {
  const {
    authentication: { logout, currentUser },
  } = useStore();
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const onSwitchOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const onCloseDropdown = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useOnClickOutside(dropdownRef, onCloseDropdown);

  return (
    <div className="relative flex-shrink-0 ml-4" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="px-3 py-2 text-sm font-medium text-white border-b-2 border-primary hover:border-white focus:border-white focus:outline-none"
          id="user-menu"
          aria-haspopup="true"
          onClick={onSwitchOpen}
        >
          <span className="sr-only">Open user menu</span>
          <span className="">
            {currentUser?.firstName} {currentUser?.lastName}
          </span>
        </button>
      </div>
      <div
        className={classnames(
          "origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1",
          isOpen ? "block" : "hidden"
        )}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        <button
          type="button"
          onClick={logout}
          className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
          role="menuitem"
        >
          Sign Out
        </button>
      </div>
    </div>
  );
});
