import { useCallback } from "react";
import { useAsync } from "react-async";
import { Controller, UseFormReturn } from "react-hook-form";
import Select from "react-select";
import { useStore } from "src/app/contexts/store.context";
interface ImportRestaurantsFormProps {
  form: UseFormReturn;
}

export const ImportRestaurantsForm = (props: ImportRestaurantsFormProps) => {
  const { form } = props;

  const {
    restaurants: { findAtpRestaurants },
  } = useStore();

  const fetchAtpRestaurants = useCallback(async () => {
    return findAtpRestaurants();
  }, [findAtpRestaurants]);

  const atpRestaurants = useAsync({
    promiseFn: fetchAtpRestaurants,
  });

  return (
    <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
      <div>
        <label htmlFor="stores" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
          Source ATP Stores
        </label>
      </div>
      <div className="col-span-2">
        <Controller
          name="stores"
          control={form.control}
          render={({ field }) => (
            <Select
              menuPosition="fixed"
              isMulti
              closeMenuOnSelect={false}
              isClearable
              classNamePrefix="tw-select"
              defaultOptions
              noOptionsMessage={() => "No Results"}
              placeholder="Select Stores to import"
              onChange={(values) => field.onChange(values)}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              options={
                atpRestaurants.data
                  ? atpRestaurants.data.map((restaurant) => {
                      return {
                        value: restaurant,
                        label: restaurant.name,
                      };
                    })
                  : []
              }
            />
          )}
        />
      </div>
    </div>
  );
};
