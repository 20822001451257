import { faStoreAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import { EngineModelType } from "src/app/models/engine.model";
import { useHistory } from "react-router-dom";

interface RestaurantDetailsProps {
  engine: any;
}

export const EngineDetails = observer((props: RestaurantDetailsProps) => {
  const { engine } = props;
  const history = useHistory();
  const goToEngineEditPage = (engine: EngineModelType) => () => {
    history.push(`/engines/engine/edit/${engine.id}`);
  };

  return (
    <div>
      <div className="p-2 md:flex md:items-center md:justify-between md:space-x-4">
        <div className="w-full space-y-6">
          <div className="flex justify-between w-full">
            <h1 className="inset-y-0 left-0 w-4/6 text-2xl font-bold capitalize text-primary">
              <FontAwesomeIcon className="mr-3 text-primary" icon={faStoreAlt} />
              {engine.name}
            </h1>
            <button
              className="  ml-2 inset-y-0 right-0 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
              onClick={goToEngineEditPage(engine)}
            >
              Edit
            </button>
          </div>
          <div className="pt-3 overflow-auto border-t border-gray-200">
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">EngineID (for the API):</span> {engine.id}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">URL:</span> {engine.url}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Description:</span> {engine.description}
              </span>
            </p>
          </div>
          <div className="pt-3 border-t border-gray-200">
            <p className="mt-2 text-xs">
              <span className="font-medium">
                <span className="font-bold">Created:</span> {engine.createdAt.toLocaleString({ ...DateTime.DATETIME_MED, month: "long" })}
              </span>
            </p>
            <p className="mt-3 text-xs">
              <span className="font-medium">
                <span className="font-bold">Last Updated:</span>{" "}
                {engine.updatedAt.toLocaleString({ ...DateTime.DATETIME_MED, month: "long" })}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
