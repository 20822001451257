import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import { IAnyType } from "mobx-state-tree";
import { ReactNode } from "react";
import { AsyncState } from "react-async";
import { PaginatedModels } from "../../models/paginated-models.model";

interface DataGridTableProps<ENTITY extends IAnyType> {
  header?: ReactNode;
  columns: { name: string; label: string, className?: string }[];
  children: (data: PaginatedModels<ENTITY>) => ReactNode;
  dataProvider: AsyncState<PaginatedModels<ENTITY>>;
}

export const DataGridTable = observer(<ENTITY extends IAnyType>(props: DataGridTableProps<ENTITY>) => {
  const { header, columns, children, dataProvider } = props;

  return (
    <table className="min-w-full table-auto">
      <thead className="relative bg-white border-b border-divider">
        {header ? (
          header
        ) : (
          <tr>
            {columns &&
              columns.map((column) => (
                <th
                  key={column.name}
                  scope="col"
                  className={`px-4 py-3 text-xs font-medium tracking-wider text-left uppercase group text-primary ${column.className}`}
                >
                  <span
                    className="text-xs font-medium tracking-wider text-left uppercase group-hover:visible text-primary whitespace-nowrap"
                  >
                    {column.label}
                  </span>
                </th>
              ))}

            {/* <th scope="col" className="relative items-center justify-center table-cell h-full">
              <span className="sr-only">Actions</span>
            </th> */}
          </tr>
        )}
      </thead>

      <tbody className="bg-white divide-y divide-divider-light">
        {dataProvider.status === "initial" && (
          <tr>
            <td colSpan={columns.length + 1}>
              <div className="flex items-center justify-center h-10 overflow-hidden rounded-sm">
                <FontAwesomeIcon icon={faSpinnerThird} className="animation animate-spin" />
              </div>
            </td>
          </tr>
        )}
        {dataProvider.status === "rejected" && (
          <tr>
            <td colSpan={columns.length + 1}>
              <div className="flex items-center justify-center h-10 overflow-hidden text-white rounded-sm bg-error">
                An error eccured: {dataProvider.error?.message || "Unknown Error"}
              </div>
            </td>
          </tr>
        )}
        {dataProvider.data && !dataProvider.data.data.length && (
          <tr>
            <td colSpan={columns.length + 1}>
              <div className="flex items-center justify-center h-10 text-white bg-primary">No Data Available</div>
            </td>
          </tr>
        )}
        {dataProvider.data && children(dataProvider.data)}
      </tbody>
    </table>
  );
});
