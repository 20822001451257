import { ReactNode } from "react";

interface HeaderLayoutProps {
  title: string | ReactNode;
  children: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
}

export const HeaderLayout = (props: HeaderLayoutProps) => {
  const { title, children, left, right } = props;

  return (
    <div className="flex flex-col flex-1">
      <header className="relative z-10 h-12 bg-white shadow">
        <div className="grid h-12 grid-cols-3 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex">{left || <div />}</div>
          <div className="flex justify-center">
          {typeof title === "string" ? (
            <h1 className="flex items-center text-lg font-semibold leading-6 justify-self-center text-primary">{title}</h1>
          ) : (
            title
          )}
          </div>
          <div className="flex">{right || <div />}</div>
        </div>
      </header>
      <main className="flex flex-grow bg-background-page">{children}</main>
    </div>
  );
};
