import clsx from "clsx";
import { Controller, UseFormReturn } from "react-hook-form";
interface DashboardFormProps {
  form: UseFormReturn;
}

interface FormInput {
  name: string;
  type: string;
  step?: string;
  placeholder?: string;
  uncheckedValue?: string | boolean;
  checkedValue?: string | boolean;
  uncheckedLabel?: string;
  checkedLabel?: string;
  options?: { value: string; label: string }[];
  label: (data: any) => string;
  showIf?: (data: any) => boolean;
  requiredIf?: (data: any) => boolean;
}

const inputs: FormInput[] = [
  {
    name: "name",
    type: "text",
    placeholder: "Kiosk A",
    label: () => "Name",
    requiredIf: () => true,
  },
  {
    name: "description",
    type: "text",
    placeholder: "This is a Dashboard",
    label: () => "Description",
    requiredIf: () => true,
  },
  {
    name: "isTitled",
    type: "switch",
    uncheckedValue: false,
    checkedValue: true,
    uncheckedLabel: "No",
    checkedLabel: "Yes",
    label: () => "Show Title",
    requiredIf: () => true,
  },
  {
    name: "isBordered",
    type: "switch",
    uncheckedValue: false,
    checkedValue: true,
    uncheckedLabel: "No",
    checkedLabel: "Yes",
    label: () => "Show Border",
    requiredIf: () => true,
  },
  {
    name: "theme",
    type: "switch",
    uncheckedValue: "day",
    checkedValue: "night",
    uncheckedLabel: "Light",
    checkedLabel: "Dark",
    label: () => "Theme",
    requiredIf: () => true,
  },
];

export const DashboardForm = (props: DashboardFormProps) => {
  const { form } = props;
  const data = form.watch();

  return (
    <>
      {inputs.map((input) =>
        !input.showIf || input.showIf(data) ? (
          <div key={input.name} className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {input.label(data)}
              </label>
            </div>
            <div className="col-span-2">
              {input.type !== "select" && input.type !== "switch" && (
                <input
                  {...form.register(input.name)}
                  type={input.type}
                  step={input.step}
                  maxLength={100}
                  placeholder={input.placeholder}
                  required={input.requiredIf && input.requiredIf(data)}
                  className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
                />
              )}
              {input.type === "switch" && (
                <div className="flex items-center w-full">
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <div>{input.uncheckedLabel}</div>
                    <div className="relative">
                      <Controller
                        name={input.name}
                        defaultValue={input.uncheckedValue}
                        control={form.control}
                        render={({ field }) => (
                          <>
                            <input
                              type="checkbox"
                              onChange={(event) => {
                                field.onChange(event.target.checked ? input.checkedValue : input.uncheckedValue);
                              }}
                              className="sr-only"
                            />
                            <div
                              className={clsx(
                                "block h-8 rounded-full w-14",
                                data[input.name] === input.checkedValue ? "bg-primary" : "bg-gray-400"
                              )}
                            ></div>
                            <div
                              className={clsx(
                                "absolute w-6 h-6 transition bg-white rounded-full left-1 top-1",
                                data[input.name] === input.checkedValue ? "transform translate-x-full" : ""
                              )}
                            ></div>
                          </>
                        )}
                      />
                    </div>
                    <div>{input.checkedLabel}</div>
                  </label>
                </div>
              )}
            </div>
          </div>
        ) : null
      )}
    </>
  );
};
