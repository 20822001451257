import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import { useAsync } from "react-async";
import { useHistory, useParams } from "react-router-dom";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { RestaurantDetails } from "src/app/components/Restaurants/RestaurantDetails";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useStore } from "src/app/contexts/store.context";

interface RestaurantViewParams {
  storeId: string;
}

export const RestaurantView = () => {
  const history = useHistory();
  const {
    restaurants: { getEntityAsync: getRestaurant },
  } = useStore();

  const { storeId } = useParams<RestaurantViewParams>();

  const fetchRestaurant = useCallback(async () => {
    return getRestaurant(+storeId, true);
  }, [getRestaurant, storeId]);

  const restaurant = useAsync({
    promiseFn: fetchRestaurant,
  });

  useEffect(() => {
    restaurant.reload();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <HeaderLayout
      left={
        <button type="button" onClick={() => history.push("/stores", { from: "RestaurantView" })}>
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          Back
        </button>
      }
      title="Stores"
    >
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
        {!restaurant.data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="flex flex-col w-full px-4 py-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ">
            <div className="xl:grid xl:grid-cols-2">
              <div className="xl:col-span-2 xl:pr-4 xl:border-gray-200">
                <div className="p-4 bg-white rounded shadow-md">
                  <RestaurantDetails restaurant={restaurant.data} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </HeaderLayout>
  );
};
